import axios from 'axios'
import { useEffect, useState } from 'react'
import {
  TransactionsChart,
  UniqueAccountsCharts,
  VolumeChart,
  /***************************/
  ChartsTransactionCount_Stats,
  ChartsUniqueAccounts_Stats,
  ChartsVolume_Stats,
  ChartsTotalFees_Stats,
  ChartsAverageFees_Stats,
  ChartsAverageTransactionSize_Stats
} from '../../../../_metronic/partials/widgets'
import InflowOutflowChart from './InflowOutflowChart';
import InflowOutflowChart_Stats from './InflowOutflowChart_Stats';
import { useLocation, useParams } from 'react-router';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@mui/styles';
import { TotalFeesChart } from '../../../../_metronic/partials/widgets/charts/TotalFeesChart';
import { AverageFeeChart } from '../../../../_metronic/partials/widgets/charts/AverageFeeChart';
import { AverageTransactionSizeChart } from '../../../../_metronic/partials/widgets/charts/AverageTransactionSize';


const useStyles = makeStyles({
  paper: {
    minWidth: "42.9vh",
    maxWidth: "42.9vh",
    minHeight: "27.9vh",
    maxHeight: "27.9vh",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});


function OverviewBasic() {
  const param = useParams();


  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [tableData, setTableData] = useState<any>([]);
  useEffect(() => {
    setIsLoading(true);

    var data: any[] = []
    axios.get("/dapps/list-dapps")
      .then((res) => {
        setTableData(res.data);
        for (var i = 0; i < res.data.length; i++) {
          data.push(res.data[i].category)
        }
      });
  }, []);


  const item = tableData.find((item: any) => item.name === param.overview);


  const [findDapp, setFindDapp] = useState<any>([]);
  useEffect(() => {
    if (
      tableData.length > 0
      &&
      item
    ) {
      const fetch = async () => {
        await axios.get(`/dapps/find-dapp/${item?.id}`)
          .then((res) => setFindDapp(res.data));

        setIsLoading(false);
      }

      fetch();
    }
  }, [tableData]);


  if(isLoading) {
    return (
      <>
        <Dialog
          open={isLoading}
          className="dialog"
          classes={{ paper: classes.paper }}
        >
          <CircularProgress size={55} />
        </Dialog>
      </>
    )  
  }


  if (
    tableData.length === 0
  ) {
    return <></>;
  }
  if (
    findDapp.length === 0
  ) {
    return <></>;
  }


  return (
    <>
      <TransactionsChart className='mt-10' findDapp={findDapp} heading="TRANSACTIONS" />
      <UniqueAccountsCharts className='mt-10' findDapp={findDapp} heading="UNIQUE ACCOUNTS" />
      <VolumeChart className='mt-10' findDapp={findDapp} heading="VOLUME (₳)" />             
      <InflowOutflowChart className='mt-10 bg-white rounded-xl p-12' findDapp={findDapp} heading="INFLOWS/OUTFLOWS(₳)" />
      <TotalFeesChart className='mt-10' findDapp={findDapp} heading="TOTAL FEES (₳)" />
      <AverageFeeChart className='mt-10' findDapp={findDapp} heading="AVERAGE FEE (₳)" />
      <AverageTransactionSizeChart className='mt-10' findDapp={findDapp} heading="AVERAGE TRANSACTION SIZE (Bytes)" />
    </>
  )
}


function OverviewGlobalStats() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [tableData, setTableData] = useState<any>([]);
  useEffect(() => {
    setIsLoading(true);

    var data: any[] = []
    axios.get("/global/stats/epochs")
      .then((res) => {
        setTableData(res.data);
        for (var i = 0; i < res.data.length; i++) {
          data.push(res.data[i].category)
        }

        setIsLoading(false);
      });
  }, []);


  if(isLoading) {
    return (
      <>
        <Dialog
          open={isLoading}
          className="dialog"
          classes={{ paper: classes.paper }}
        >
          <CircularProgress size={55} />
        </Dialog>
      </>
    )  
  }


  if (
    tableData.length === 0
  ) {
    return <></>;
  }


  return (
    <>
      <ChartsTransactionCount_Stats className='mt-10' tableData={tableData} heading="TRANSACTIONS" />
      <ChartsUniqueAccounts_Stats className='mt-10' tableData={tableData} heading="UNIQUE ACCOUNTS" />
      <ChartsVolume_Stats className='mt-10' tableData={tableData} heading="VOLUME (₳)"/>
      <InflowOutflowChart_Stats className='mt-10 bg-white rounded-xl p-12' tableData={tableData} heading="INFLOWS/OUTFLOWS(₳)"/>
      <ChartsTotalFees_Stats className='mt-10' tableData={tableData} heading="TOTAL FEES (₳)" />
      <ChartsAverageFees_Stats className='mt-10' tableData={tableData} heading="AVERAGE FEES (₳)" />
      <ChartsAverageTransactionSize_Stats className='mt-10' tableData={tableData} heading="AVERAGE TRANSACTION SIZE (Bytes)" />
    </>
  )
}


const Overview = () => {
  const location = useLocation();


  if(
    location.pathname === "/analytics/globalstats"
  ) {
    return <OverviewGlobalStats/>;
  }
  
  return <OverviewBasic/>;
}


export { Overview }
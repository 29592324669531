import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { discord, twitter, telegram, github } from '../../_metronic/layout/images';
import { fb,insta } from '../../_metronic/images';

function About() {
    const screen750 = useMediaQuery('(min-width:750px)');
    const screen644 = useMediaQuery('(max-width:644px)');
    const screen580 = useMediaQuery('(min-width:580px)');


    return (
        <div className="bg-[#eee]">

            <div className="bg-white text-black pt-20 pb-14" style={{ borderRadius: "15px", padding: "55px", marginBottom: "35px" }}>
                <div className="w-[80%]" style={{ margin: "0 auto" }}>

                    <p className="text-6xl text-center font-bold" style={{ wordSpacing: "3.15px", fontSize: screen580 ? (!screen750 ? "3.35rem" : "") : "2.9rem" }}>
                        About Us
                    </p>

                    <p className="mt-10 leading-5 text-gray-600 text-justify">
                        Dappsoncardano.com is an analytics platform dedicated to the decentralized applications (dapps) built on the Cardano blockchain. The website is the result of a collaborative effort between <b> <a href="https://adriapool.net">ADRIA pool</a> </b> and<b> <a href="https://cardano.fans">CRFA pool</a></b>, two stake pool operators in the Cardano ecosystem.
                        The goal of the site is to offer a comprehensive source of information, details, and metrics related to the dapps on Cardano. Our aim is to provide you with a clear and concise understanding of the dapps on Cardano and their potential impact on the ecosystem.
                        <br />
                        <br />
                        If you have any inquiries or suggestions, we welcome you to reach out to us through any of our channels. Our team is always open to feedback and suggestions to improve our platform and better serve our community.
                        <br />
                        <br />
                        <div className={`d-flex align-items-center mr-1 mb-3 ${screen644 && `mt-3 mb-0`}`}>
                        <a target="_blank" className="ml-1" href="https://t.co/o3MYzdlb5E"> <img src={discord} style={{ width: "27px", height: "21px" }} /> </a>
                        <a target="_blank" className="ml-5" href="https://twitter.com/dappsoncardano"> <img src={twitter} style={{ width: "28px", height: "22px" }} /> </a>
                        <a target="_blank" className="ml-2" href="https://t.me/+C_qqAkWTBoYyYzhi"> <img src={telegram} style={{ width: "40px", height: "38px" }} /> </a>
                        <a target="_blank" className="ml-3" href="https://facebook.com/dappsoncardano"> <img src={fb} style={{ width: "25px", height: "25px" }} /> </a>
                        <a target="_blank" className="ml-3" href="https://instagram.com/dappsoncardano"> <img src={insta} style={{ width: "25px", height: "25px" }} /> </a>
                        </div>
                    </p>

                    <div className="mt-7">
                        <p className="text-2xl font-bold tracking-wide" style={{ wordSpacing: "0.65px" }}>
                            ADRIA Stake Pool
                        </p>
                        <p className="mt-2 leading-5 text-gray-600 text-justify">
                            Adria Pool is a single stake pool operator (SPO) that has been providing support to the Cardano blockchain since 2021. We are committed to promoting decentralization and empowering individuals in the network.
                            Our pool is dedicated to educating and raising awareness of Cardano in the Balkan region, and we aim to provide reliable and efficient infrastructure for the Cardano blockchain.
                            In our effort to contribute to the growth and development of Cardano, we have established various projects such as dappsoncardano.com and maintain a strong social media presence on Twitter and Facebook. We believe in transparency and strive to keep our community informed and engaged.
                            <br />
                            <br />
                            Adria Pool is proud to offer a zero-fee staking experience and additional rewards to our delegators. Our rewards program is regularly updated and can be easily accessed on our website at <b> <a href="https://adriapool.net/rewards">ADRIA DELEGATORS REWARDS</a></b>. Our delegation rewards program currently includes<b> Sundae, cNeta, Hosky, and Bison </b>.
                            <br />
                            <br />
                            When you delegate your ADA with Adria Pool, you are making a valuable contribution to the security and decentralization of the Cardano network. In return, you will receive competitive rewards for your contribution. We are committed to providing a secure and profitable staking experience to our delegators and building long-lasting relationships with our community. Contact us if you have any questions or need assistance, and we will be happy to help.
                        </p>
                    </div>

                    <div className={`d-flex align-items-center mr-1 mb-3 ${screen644 && `mt-3 mb-0`}`}>
                        <a target="_blank" className="ml-1" href="https://t.co/o3MYzdlb5E"> <img src={discord} style={{ width: "27px", height: "21px" }} /> </a>
                        <a target="_blank" className="ml-5" href="https://twitter.com/adriastakepool"> <img src={twitter} style={{ width: "28px", height: "22px" }} /> </a>
                        <a target="_blank" className="ml-2" href="https://t.me/+C_qqAkWTBoYyYzhi"> <img src={telegram} style={{ width: "40px", height: "38px" }} /> </a>
                        <a target="_blank" className="ml-3" href="https://www.facebook.com/adriastakepool"> <img src={fb} style={{ width: "25px", height: "25px" }} /> </a>
                        <a target="_blank" className="ml-3" href="https://instagram.com/adria.stakepool"> <img src={insta} style={{ width: "25px", height: "25px" }} /> </a>
                    </div>

                    <div className={`mt-7 flex items-center justify-around ${!screen580 && `flex-col`}`}>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 72 </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Delegators </span>
                        </Card>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column", marginTop: !screen750 ? "13px" : "" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 16.4m ADA </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Staked </span>
                        </Card>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column", marginTop: !screen750 ? "13px" : "" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 52k ADA </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Pledge </span>
                        </Card>
                    </div>

                    <div className="mt-7">
                        <p className="text-2xl font-bold tracking-wide" style={{ wordSpacing: "0.65px" }}>
                            CRFA Stake Pool
                        </p>
                        <p className="mt-2 leading-5 text-gray-600 text-justify">
                            CRFA stake pool is a Cardano stake pool operator that has been active since the Incentivized Testnet (ITN) phase of the Cardano network.
                            The pool has a substantial pledge of 1 million ADA and has contributed significantly to the Cardano ecosystem by participating in the production of over 3,000 blocks on the mainnet. In addition to its stake pool operations, CRFA is also involved in several other projects related to the Cardano ecosystem, such as <a href="https://lookerstudio.google.com/u/0/reporting/3136c55b-635e-4f46-8e4b-b8ab54f2d460/page/p_wxcw6g0irc">Cardano Blockchain Insights</a> and dappsoncardano.com.
                            <br />
                            <br />
                            When you stake your ADA with CRFA, you are delegating your funds to a pool that is run by professional and experienced individuals who are deeply invested in the Cardano ecosystem.
                            The pool demonstrates proficiency in the administration, producing blocks consistently, and distributing rewards to their delegators in a transparent and fair manner. By staking with CRFA, you can be confident that your ADA is in the hands of individuals who are dedicated to contributing to the growth and development of the Cardano network. This provides you with peace of mind and the assurance that your staked ADA is being used to support the network and earn you rewards.                            <br />
                            <br />
                            Please use the contact information provided below to get in touch with us. We look forward to hearing from you!                        </p>
                    </div>

                    <div className={`d-flex align-items-center mr-1 mb-3 ${screen644 && `mt-3 mb-0`}`}>                        
                        <a target="_blank" className="ml-5" href="https://twitter.com/matiwinnetou"> <img src={twitter} style={{ width: "28px", height: "22px" }} /> </a>
                        <a target="_blank" className="ml-2" href="https://t.me/freedom_cardano"> <img src={telegram} style={{ width: "40px", height: "38px" }} /> </a>
                        <a target="_blank" className="ml-1" href="https://t.co/o3MYzdlb5E"> <img src={discord} style={{ width: "27px", height: "21px" }} /> </a>
                        <a target="_blank" className="ml-3" href="https://github.com/Cardano-Fans/crfa-offchain-data-registry"> <img src={github} style={{ width: "25px", height: "25px" }} /> </a>
                    </div>

                    <div className={`mt-7 flex items-center justify-around ${!screen580 && `flex-col`}`}>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 869 </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Delegators </span>
                        </Card>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column", marginTop: !screen750 ? "13px" : "" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 23.2m ADA </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Staked </span>
                        </Card>
                        <Card sx={{ padding: !screen750 ? "37px" : "50px", paddingLeft: !screen750 ? "35px" : "60px", paddingRight: !screen750 ? "35px" : "60px", paddingTop: !screen750 ? "25px" : "", paddingBottom: !screen750 ? "30px" : "", borderRadius: "180px", display: "flex", alignItems: "center", flexDirection: "column", marginTop: !screen750 ? "13px" : "" }}>
                            <AccessibilityIcon fontSize="large" />
                            <span style={{ fontSize: !screen750 ? "1.6rem" : "2rem" }}> 1m ADA </span>
                            <span style={{ fontSize: !screen750 ? "0.9rem" : "1rem" }}> Pledge </span>
                        </Card>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default About;
import { useState } from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";


export function MenuInner() {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();

  const [hoverProduct, setHoverProduct] = useState<boolean>(false);

  return (
    <>
      <div className="mt-1.5">
        <button
          style={{ backgroundColor: location.pathname !== '/' ? '' : 'rgba(255, 255, 255, 0.1)', color: 'white', border: 'none', padding: "0.85rem", paddingLeft: "0.95rem", paddingRight: "0.95rem", borderRadius: "7px" }}
          onClick={() => { if (location.pathname !== '/') { navigate('/') } }}
          className="flex items-center justify-between font-medium tracking-wide text-[0.965rem] text-[hsla(0,0%,100%,.7)] hover:text-whiter"
        >
          Home
        </button>
      </div>

      <div className="mt-1.5" onMouseLeave={() => setHoverProduct(false)}>
        <button
          style={{ backgroundColor: location.pathname !== '/analytics/globalstats' ? '' : 'rgba(255, 255, 255, 0.1)', color: 'white', border: 'none', padding: "0.85rem", paddingLeft: "0.95rem", paddingRight: "0.95rem", borderRadius: "7px" }}
          onMouseOver={() => setHoverProduct(true)}
          onClick={() => setHoverProduct(prev => !prev)}
          className="flex items-center justify-between font-medium tracking-wide text-[0.965rem] text-[hsla(0,0%,100%,.7)] hover:text-whiter"
        >
          <span className="">Analytics</span> {hoverProduct ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
        </button>

        <div className="w-full h-0 -mt-5 flex items-center justify-between sticky top-0">
          <div className="mt-28 flex items-center">
            {hoverProduct && (
              <div className="bg-whiter rounded-md p-4 w-[26rem] flex justify-center">
                <a href="/analytics/globalstats" className="flex flex-col items-start cursor-pointer p-4 pl-9 pr-9 bg-gray-200 rounded-md ml-0.5">
                  <p className="text-lightest text-black text-sm mt-3"> Global </p>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-1.5">
        <button
          style={{ backgroundColor: location.pathname !== '/analytics/globalstats' ? '' : 'rgba(255, 255, 255, 0.1)', color: 'white', border: 'none', padding: "0.85rem", paddingLeft: "0.95rem", paddingRight: "0.95rem", borderRadius: "7px", whiteSpace: "nowrap" }}
          onClick={() => { if (location.pathname !== '/about') { navigate('/about') } }}
          className="-ml-2.5 flex items-center justify-between font-medium tracking-wide text-[0.965rem] text-[hsla(0,0%,100%,.7)] hover:text-whiter"
        >
          About Us
        </button>
      </div>

      <div className="mt-1.5">
        <button
          style={{ backgroundColor: location.pathname !== '/advertise' ? '' : 'rgba(255, 255, 255, 0.1)', color: 'white', border: 'none', padding: "0.85rem", paddingLeft: "0.95rem", paddingRight: "0.95rem", borderRadius: "7px", whiteSpace: "nowrap" }}
          onClick={() => { if (location.pathname !== '/advertise') { navigate('/advertise') } }}
          className="-ml-2.5 flex items-center justify-between font-medium tracking-wide text-[0.965rem] text-[hsla(0,0%,100%,.7)] hover:text-whiter"
        >
          Advertise
        </button>
      </div>       
    </>
  )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'


type Props = {
  className: string;
  findDapp?: any;
  heading: string;
}

const UniqueAccountsCharts: React.FC<Props> = ({ className, findDapp, heading }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)


  const [vertical, setVertical] = useState<any>([]);
  const [horizontal, setHorizontal] = useState<any>([]);
  useEffect(() => {
    if (findDapp && findDapp?.epochLevelData && findDapp?.epochLevelData?.epochData) {
      setVertical(
        Object.keys(findDapp?.epochLevelData?.epochData)
          .sort()
      );

      setHorizontal(
        Object.values(findDapp?.epochLevelData?.epochData)
          .map((item: any) => item.uniqueAccounts)
      )
    }
  }, [findDapp]);


  const keys = [
    'All Time',
    '18 epochs',
    '6 epochs',
    "Current"
  ];
  const [active, setActive] = useState<number>(2);
  const [current_graph, setCurrent_graph] = useState({ vertical: [], horizontal: [] });
  useEffect(() => {
    if(active === 0) {
      setCurrent_graph({
        vertical: vertical.slice( 0, vertical.length - 1 ),
        horizontal: horizontal.slice( 0, vertical.length - 1 )
      });
    }
    else if(active === 1) {
      setCurrent_graph({
        vertical: vertical.slice( vertical.length - 18, vertical.length - 1 ),
        horizontal: horizontal.slice( vertical.length - 18, vertical.length - 1 )
      });
    }
    else if(active === 2) {
      setCurrent_graph({
        vertical: vertical.slice( vertical.length - 7, vertical.length - 1 ),
        horizontal: horizontal.slice( vertical.length - 7, vertical.length - 1 )
      }); 
    }     
    else if(active === 3) {
        setCurrent_graph({
          vertical: vertical.slice( vertical.length - 1 ),
          horizontal: horizontal.slice( vertical.length - 1 )
        });      
    }
  }, [vertical, horizontal, active]);


  function getChartOptions(vertical: any, horizontal: any): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const baseColor = getCSSVariableValue('--bs-info')
    const lightColor = getCSSVariableValue('--bs-light-info')

    return {
      series: [
        {
          name: 'Unique Accounts',
          data: horizontal?.length === 0 ? [] : horizontal,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: vertical?.length === 0 ? [] : vertical,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '' + val 
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: baseColor,
        strokeWidth: 3,
      },
    }
  }


  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(
      current_graph.vertical,
      current_graph.horizontal
    ))

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, current_graph]);


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{heading}</span>
          <span className='text-muted fw-bold fs-7'>Per Epoch</span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          {
            keys.map((item: any, i: number) => (
              <a
                className={`${(i === active) && `active`} btn btn-sm btn-color-muted btn-active btn-active-primary px-4`}
                id='kt_charts_widget_3_week_btn'
                onClick={() => { setActive(i); }}
              >
                {item}
              </a>
            ))
          }
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { UniqueAccountsCharts }
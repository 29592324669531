import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate, useLocation } from 'react-router-dom';


type Anchor = 'left';
export default function TemporaryDrawer({
    state,
    toggleDrawer
}: {
    state: any,
    toggleDrawer: Function
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState("");


    const list = (anchor: Anchor) => (
        <Box
            role="presentation"
        >
            <List
                style={{ width: "18rem" }}
            >
                {
                    [
                        { name: 'Home', list: [], icon: <PublicIcon fontSize="medium" />, url: "/" },
                        { name: 'Analytics', list: [{ text: 'Global Stats', icon: <PublicIcon fontSize="medium" /> }], icon: null, url: "/analytics/globalstats" },
                        { name: 'About Us', list: [], icon: <PublicIcon fontSize="medium" />, url: "/about" },
                        { name: 'Advertise', list: [], icon: <PublicIcon fontSize="medium" />, url: "/advertise" },
                    ]
                        .map((item, i) => (
                            <div
                                key={i}
                                className="pt-8 pl-10 pr-10"
                            >
                                {
                                    item.list
                                        .length >= 1 ? (
                                        <>
                                            <div
                                                className="text-blue-500 font-medium tracking-wide w-full flex items-center"
                                                onClick={() => {
                                                    if (open !== "") setOpen(item.name);
                                                    if (open === item.name) setOpen("");
                                                    if (open === "") setOpen(item.name);
                                                }}
                                            >
                                                <span className="flex-1">
                                                    {item.name}
                                                </span>
                                                {
                                                    (item.name === open) ?
                                                        <KeyboardArrowUpIcon />
                                                        : <KeyboardArrowDownIcon />
                                                }
                                            </div>
                                            {
                                                (item.name === open) && (
                                                    <div className="mt-4">
                                                        {
                                                            item.list
                                                                .map((item_, i) => (
                                                                    <div
                                                                        key={i}
                                                                        className="flex items-center"
                                                                        onKeyDown={toggleDrawer(anchor, false)}
                                                                        onClick={() => {
                                                                            if (location.pathname !== item.url) {
                                                                                navigate(item.url);
                                                                                toggleDrawer(anchor, false);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {item_.icon}
                                                                        <span className="ml-2"> {item_.text} </span>
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <div
                                            className="flex items-center -ml-1"
                                            onKeyDown={toggleDrawer(anchor, false)}
                                            onClick={() => {
                                                if (location.pathname !== item.url) {
                                                    navigate(item.url);
                                                    toggleDrawer(anchor, false);                                                    
                                                }
                                            }}
                                        >
                                            {item.icon}
                                            <span className="ml-2"> {item.name} </span>
                                        </div>
                                    )
                                }
                            </div>
                        ))
                }
            </List>
        </Box>
    )


    return (
        <>
            {
                (['left'] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button onClick={toggleDrawer(anchor, true)}> {anchor} </Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))
            }
        </>
    );
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useLocation, useParams } from 'react-router'
import { AiOutlineTwitter } from 'react-icons/ai'
import { TbWorld } from 'react-icons/tb'
import millify from 'millify';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  paper: {
    minWidth: '42.9vh',
    maxWidth: '42.9vh',
    minHeight: '27.9vh',
    maxHeight: '27.9vh',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const AccountHeaderBasic: React.FC = () => {
  const screen287 = useMediaQuery('(max-width:287px)')
  const screen320 = useMediaQuery('(max-width:320px)')
  const screen325 = useMediaQuery('(max-width:325px)')
  const screen350 = useMediaQuery('(max-width:350px)')
  const screen392 = useMediaQuery('(max-width:392px)')
  const screen480 = useMediaQuery('(max-width:480px)')
  const screen639 = useMediaQuery('(max-width:639px)')
  const screen760 = useMediaQuery('(max-width:760px)')
  const screen767 = useMediaQuery('(max-width:767px)')
  const screen820 = useMediaQuery('(max-width:820px)')
  const screen1024 = useMediaQuery('(max-width:1024px)')

  const param = useParams()

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [tableData, setTableData] = useState<any>([])
  useEffect(() => {
    setIsLoading(true)

    var data: any[] = []
    axios.get('/dapps/list-dapps').then((res) => {
      setTableData(res.data)
      for (var i = 0; i < res.data.length; i++) {
        data.push(res?.data[i]?.category)
      }

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <>
        <Dialog open={isLoading} className='dialog' classes={{ paper: classes.paper }}>
          <CircularProgress size={55} />
        </Dialog>
      </>
    )
  }

  if (tableData.length === 0) {
    return <></>
  }

  const item = tableData.find((item: any) => item.name === param.overview)

  const percentage = item?.epochLevelData?.lastEpochDeltaWithOnlyClosedEpochs?.activityDiffPerc
    ? (item?.epochLevelData?.lastEpochDeltaWithOnlyClosedEpochs?.activityDiffPerc).toFixed(0) + '%'
    : ''

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl(item?.icon)} alt='Dapp' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {item?.fullName ? item.fullName : item?.name}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen017.svg'
                      className='svg-icon-4 me-1'
                    />
                    {item?.category}
                  </a>
                  <a className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {item?.subCategory}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                <a className='btn btn-sm btn-light me-2' href={item?.twitter} target='_blank'>
                  <AiOutlineTwitter size={20} style={{ color: '#009ef7' }} />
                </a>

                <a
                  className='btn btn-sm btn-primary me-3'
                  href={item?.link}
                  target='_blank'
                  style={{ marginRight: -10 }}
                >
                  <TbWorld size={20} style={{ color: 'white' }} />
                </a>
              </div>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-4 gap-2' style={{ marginTop: '-0.35rem' }}>
              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Transactions{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(item?.trxCount)}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Unique Accounts
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{millify(item?.uniqueAccounts)}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Last Epoch Activity
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{percentage}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Transaction Fees{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(item?.trxFees) + " ₳"}</span>
                  </span>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-4 gap-2' style={{ marginTop: '-0.35rem' }}>
              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Volume{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(item?.volume) + " ₳"}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Scripts Balance
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{millify(item?.scriptsLocked)}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px', paddingTop: screen325 ? "1.305rem" : "" }}
                  >
                    <span
                      className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}
                      dangerouslySetInnerHTML={{ __html: !screen325 ? `Avg Transaction Size` : `Avg Transaction <br/> Size` }}
                    />
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? screen325
                                          ? '0.01rem'
                                          : '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    {
                      screen325 ? (
                        <p style={{ marginTop: "-0.35rem" }}>{millify(item?.avgTrxSize)} Bytes</p>
                      ) : (
                        <span>{millify(item?.avgTrxSize)} Bytes</span>
                      )
                    }
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px', paddingTop: screen325 ? "1.305rem" : "" }}
                  >
                    <span
                      className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}
                      dangerouslySetInnerHTML={{ __html: !screen325 ? `Avg Transaction Fee` : `Avg Transaction <br/> Fee` }}
                    />
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? screen325
                                          ? '0.01rem'
                                          : '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    {
                      screen325 ? (
                        <p style={{ marginTop: "-0.35rem" }}>{item?.avgTrxFee.toFixed(3) + " ₳"}</p>
                      ) : (
                        <span>{item?.avgTrxFee.toFixed(3) + " ₳"}</span>
                      )
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6 active'
                to=''
                style={{ borderRadius: 0 }}
              >
                Overview
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const AccountHeaderGlobalStats: React.FC = () => {
  const screen287 = useMediaQuery('(max-width:287px)')
  const screen320 = useMediaQuery('(max-width:320px)')
  const screen325 = useMediaQuery('(max-width:325px)')
  const screen350 = useMediaQuery('(max-width:350px)')
  const screen392 = useMediaQuery('(max-width:392px)')
  const screen480 = useMediaQuery('(max-width:480px)')
  const screen639 = useMediaQuery('(max-width:639px)')
  const screen760 = useMediaQuery('(max-width:760px)')
  const screen767 = useMediaQuery('(max-width:767px)')
  const screen820 = useMediaQuery('(max-width:820px)')
  const screen1024 = useMediaQuery('(max-width:1024px)')

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [tableData, setTableData] = useState<any>(null)
  useEffect(() => {
    setIsLoading(true)

    var data: any[] = []
    axios.get('/global/stats').then((res) => {
      setTableData(res.data)
      for (var i = 0; i < res.data.length; i++) {
        data.push(res?.data[i]?.category)
      }

      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <>
        <Dialog open={isLoading} className='dialog' classes={{ paper: classes.paper }}>
          <CircularProgress size={55} />
        </Dialog>
      </>
    )
  }

  if (tableData === null) {
    return <></>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>

          <div className='flex-grow-1'>
            <div className='grid grid-cols-2 sm:grid-cols-4 gap-2' style={{ marginTop: '-0.35rem' }}>
              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Transactions{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(tableData?.trxCount)}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Unique Accounts
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{millify(tableData?.totalUniqueAccounts)}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Total Balance
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{millify(tableData?.balance) + "₳"}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Transaction Fees{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(tableData?.trxFees)}</span>
                  </span>
                </div>
              </div>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-4 gap-2' style={{ marginTop: '-0.35rem' }}>
              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    Volume{' '}
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: screen1024
                        ? screen820
                          ? screen767
                            ? screen760
                              ? screen639
                                ? screen392
                                  ? screen350
                                    ? '0.65rem'
                                    : '0.85rem'
                                  : '0.2rem'
                                : '0.9rem'
                              : '0.025rem'
                            : '0.9rem'
                          : '0.1rem'
                        : '0.675rem',
                    }}
                  >
                    <span>{millify(tableData?.volume) + "₳"}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px' }}
                  >
                    <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>
                      Total Dapps
                    </span>
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    <span>{tableData?.totalDappsCount}</span>
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px', paddingTop: screen325 ? "1.305rem" : "" }}
                  >
                    <span
                      className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}
                      dangerouslySetInnerHTML={{ __html: !screen325 ? `Avg Transaction Size` : `Avg Transaction <br/> Size` }}
                    />
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? screen325
                                          ? '0.01rem'
                                          : '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    {
                      screen325 ? (
                        <p style={{ marginTop: "-0.35rem" }}>{millify(tableData?.avgTrxSize)}</p>

                      ) : (
                        <span>{millify(tableData?.avgTrxSize)}</span>
                      )
                    }
                  </span>
                </div>
              </div>

              <div className='bg-white rounded-md'>
                <div
                  className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`
                    } flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-xl`}
                  style={{ backgroundColor: 'white' }}
                >
                  <span
                    className={`font-semibold w-full text-center text-black ${!screen287
                      ? screen480
                        ? screen392
                          ? screen350
                            ? `text-xs`
                            : `text-sm`
                          : `text-[0.915rem]`
                        : `text-lg`
                      : `text-xs`
                      } sm:text-base lg:text-lg`}
                    style={{ wordSpacing: '0.485px', paddingTop: screen325 ? "1.305rem" : "" }}
                  >
                    <span
                      className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}
                      dangerouslySetInnerHTML={{ __html: !screen325 ? `Avg Transaction Fee` : `Avg Transaction <br/> Fee` }}
                    />
                  </span>
                  <span
                    className='w-full text-center text-sm tracking-wide'
                    style={{
                      color: 'rgb(99, 100, 95)',
                      fontSize: !screen350 ? (screen1024 ? '' : '1rem') : '0.8rem',
                      marginTop: !screen287
                        ? !screen320
                          ? screen1024
                            ? screen820
                              ? screen767
                                ? screen760
                                  ? screen639
                                    ? screen392
                                      ? screen350
                                        ? screen325
                                          ? '0.01rem'
                                          : '0.65rem'
                                        : '0.85rem'
                                      : '0.2rem'
                                    : '0.9rem'
                                  : '0.025rem'
                                : '0.9rem'
                              : '0.1rem'
                            : '0.675rem'
                          : '0.515rem'
                        : '0.335rem',
                    }}
                  >
                    {
                      screen325 ? (
                        <p style={{ marginTop: "-0.35rem" }}>{tableData?.avgTrxFee.toFixed(3) + "₳"}</p>
                      ) : (
                        <span>{tableData?.avgTrxFee.toFixed(3) + "₳"}</span>
                      )
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className='nav-link text-active-primary me-6 active'
                to=''
                style={{ borderRadius: 0 }}
              >
                Overview
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const AccountHeader = () => {
  const location = useLocation()

  if (location.pathname === '/analytics/globalstats') {
    return <AccountHeaderGlobalStats />
  }

  return <AccountHeaderBasic />
}

export { AccountHeader }
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import Drawer from './Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';


export default function useWindowPosition() {
  const [scrollPosition, setPosition] = React.useState(0);
  React.useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
}


type Anchor = 'left';
export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  const screen992 = useMediaQuery('(min-width:992px)');
  const screen490 = useMediaQuery('(min-width:490px)');
  const screen334 = useMediaQuery('(min-width:334px)');



  const [state, setState] = React.useState({
    left: false
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };



  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
      style={{ background: useWindowPosition() > 100 ? "#088fb8" : "transparent" }}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}

        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0 flex'>
          {
            !screen992 && (
              <MenuIcon onClick={() => setState(prev => { return { left: true } })} style={{ fill: "white" }} fontSize={screen334 ? "large" : "medium"} />
            )
          }
          {
            state.left && (
              <Drawer toggleDrawer={toggleDrawer} state={state} />
            )
          }
          <Link to='/'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-demo2.png')}
              className={`logo-default ml-1.5 ${screen334 ? (screen490 ? (screen334 ? `h-25px mt-1` : ``) : `h-20px mt-0.5`) : `h-15px -mt-1`}`}
            />
          </Link>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}

import { useState, useEffect } from 'react';
import axios from 'axios';
import { BsInfoCircle } from 'react-icons/bs';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DelegateButton } from '../../../../app/wallet/features/wallet';
import millify from 'millify';


export default function () {
  const screen287 = useMediaQuery('(max-width:287px)');
  const screen309 = useMediaQuery('(max-width:309px)');
  const screen313 = useMediaQuery('(max-width:313px)');
  const screen320 = useMediaQuery('(max-width:320px)');
  const screen350 = useMediaQuery('(max-width:350px)');
  const screen381 = useMediaQuery('(max-width:381px)');
  const screen392 = useMediaQuery('(max-width:392px)');
  const screen478 = useMediaQuery('(max-width:478px)');
  const screen480 = useMediaQuery('(max-width:480px)');
  const screen512 = useMediaQuery('(max-width:512px)');
  const screen639 = useMediaQuery('(max-width:639px)');
  const screen760 = useMediaQuery('(max-width:760px)');
  const screen767 = useMediaQuery('(max-width:767px)');
  const screen779 = useMediaQuery('(max-width:779px)');
  const screen820 = useMediaQuery('(max-width:820px)');
  const screen1024 = useMediaQuery('(max-width:1024px)');


  const [headerData, setHeaderData] = useState<any>(undefined);
  useEffect(() => {
    axios.get("/global/stats")
      .then((res) => {
        setHeaderData(res.data);
      });
  }, []);

  if (headerData === undefined) return null;


  return (
    <>
      <div className={`${screen639 && `flex justify-center`}`} style={{ marginTop: !screen309?(!screen381?(!screen478?(!screen512?(!screen767?(screen779?"-0.1rem":"-0.35rem"):"0.5rem"):"0.8rem"):"-0.28rem"):"-0.7rem"):"-0.6rem" }}>
        <DelegateButton />
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2" style={{ marginTop: "-0.35rem" }}>

        <div className="bg-white rounded-md">
          <div className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`} flex flex-col items-center justify-center`} style={{ backgroundColor: "#eee" }}>
            <span className={`font-semibold w-full text-center text-black ${!screen287 ? (screen480 ? (screen350 ? `text-sm` : `text-[0.915rem]`) : `text-lg`) : `text-xs`} sm:text-base lg:text-lg`} style={{ wordSpacing: "0.485px" }}>
              Total Transactions
            </span>
            <span className="w-full text-center text-sm tracking-wide" style={{ color: "rgb(99, 100, 95)", fontSize: screen1024 ? "" : "1rem", marginTop: screen1024 ? (screen820 ? (screen767 ? (screen760 ? (screen639 ? (screen392 ? (screen350 ? "1.25rem" : "1.385rem") : "0.2rem") : "0.9rem") : "0.025rem") : "0.9rem") : "0.1rem") : "0.675rem" }}>
              <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{headerData.trxCount}</span>} style={{ cursor: "pointer" }}>
                <span>{millify(headerData.trxCount)}</span>
              </Tooltip>
            </span>
          </div>
        </div>

        <div className="bg-white rounded-md">
          <div className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`} flex flex-col items-center justify-center`} style={{ backgroundColor: "#eee" }}>
            <span className={`font-semibold w-full text-center flex items-center justify-center ${screen320 && `flex-col`} text-black ${!screen287 ? (screen480 ? (screen350 ? `text-sm` : `text-[0.915rem]`) : `text-lg`) : `text-xs`} sm:text-base lg:text-lg`} style={{ wordSpacing: "0.485px" }}>
              <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>Total Scripts Balance</span>
              <Tooltip title={<span style={{ color: "white", fontSize: "1.035rem", textAlign: "justify" }}> Total Script Balance - value corresponds to ADA balance on all contract addresses associated with app dApps listed on our platform </span>} style={{ cursor: "pointer" }}>
                <span style={{ marginLeft: !screen313 ? (screen320 ? "-0.315rem" : "") : "-0.565rem", marginTop: screen350 ? "0.175rem" : "" }}> <BsInfoCircle style={{ marginLeft: "0.345rem" }} /> </span>
              </Tooltip>
            </span>
            <span className="w-full text-center text-sm tracking-wide" style={{ color: "rgb(99, 100, 95)", fontSize: screen1024 ? "" : "1rem", marginTop: !screen287 ? (!screen320 ? (screen1024 ? (screen820 ? (screen767 ? (screen760 ? (screen639 ? (screen392 ? (screen350 ? "1.25rem" : "1.385rem") : "0.2rem") : "0.9rem") : "0.025rem") : "0.9rem") : "0.1rem") : "0.675rem") : "0.515rem") : "0.335rem" }}>
              <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{headerData.totalScriptsLocked}</span>} style={{ cursor: "pointer" }}>
                <span>{millify(headerData.totalScriptsLocked)}₳</span>
              </Tooltip>
            </span>
          </div>
        </div>

        <div className="bg-white rounded-md">
          <div className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`} flex flex-col items-center justify-center`} style={{ backgroundColor: "#eee" }}>
            <span className={`font-semibold w-full text-center flex items-center justify-center ${screen320 && `flex-col`} text-black ${!screen287 ? (screen480 ? (screen350 ? `text-sm` : `text-[0.915rem]`) : `text-lg`) : `text-xs`} sm:text-base lg:text-lg`} style={{ wordSpacing: "0.485px" }}>
              <span className={`${screen320 ? `mt-1 leading-4` : `whitespace-nowrap`}`}>Total Unique Accounts</span>
              <Tooltip title={<span style={{ color: "white", fontSize: "1.035rem", textAlign: "justify" }}> Value corresponds to total number of stake key addresses. </span>} style={{ cursor: "pointer" }}>
                <span style={{ marginLeft: !screen313 ? (screen320 ? "-0.315rem" : "") : "-0.565rem", marginTop: screen350 ? "0.175rem" : "" }}> <BsInfoCircle style={{ marginLeft: "0.345rem" }} /> </span>
              </Tooltip>
            </span>
            <span className="w-full text-center text-sm tracking-wide" style={{ color: "rgb(99, 100, 95)", fontSize: screen1024 ? "" : "1rem", marginTop: !screen287 ? (!screen320 ? (screen1024 ? (screen820 ? (screen767 ? (screen760 ? (screen639 ? (screen392 ? (screen350 ? "1.25rem" : "1.385rem") : "0.2rem") : "0.9rem") : "0.025rem") : "0.9rem") : "0.1rem") : "0.675rem") : "0.515rem") : "0.335rem" }}>
              <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{headerData.totalUniqueAccounts}</span>} style={{ cursor: "pointer" }}>
                <span>{millify(headerData.totalUniqueAccounts)}</span>
              </Tooltip>
            </span>
          </div>
        </div>

        <div className="bg-white rounded-md">
          <div className={`m-2 ${screen1024 ? (screen350 ? `h-16` : `h-20`) : `h-24`} flex flex-col items-center justify-center`} style={{ backgroundColor: "#eee" }}>
            <span className={`font-semibold w-full text-center text-black ${!screen287 ? (screen480 ? (screen350 ? `text-sm` : `text-[0.915rem]`) : `text-lg`) : `text-xs`} sm:text-base lg:text-lg`} style={{ wordSpacing: "0.485px" }}>
              Total Transactions Fees
            </span>
            <span className="w-full text-center text-sm tracking-wide" style={{ color: "rgb(99, 100, 95)", fontSize: screen1024 ? "" : "1rem", marginTop: screen1024 ? (screen820 ? (screen767 ? (screen760 ? (screen639 ? (screen392 ? (screen350 ? "1.25rem" : "1.385rem") : "0.2rem") : "0.9rem") : "0.025rem") : "0.9rem") : "0.1rem") : "0.675rem" }}>
              <span>{millify(headerData.trxFees)}₳</span>
            </span>
          </div>
        </div>

      </div>
    </>
  )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { pool, circle } from '../images';
import { useMediaQuery } from 'react-responsive';
import { discord, twitter, telegram, github } from '../images';


const Footer: FC = () => {
  const { classes } = useLayout()
  const screen644 = useMediaQuery({ query: '(max-width: 644px)' });
  const screen459 = useMediaQuery({ query: '(min-width: 459px)' });


  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-md-row ${screen644 && `flex-column`} align-items-center justify-content-between`}
        style={{ marginTop: screen644 ? "-1.595rem" : "" }}
      >
        {/* begin::Copyright */}
        <div className='text-dark flex items-center justify-between' style={{ fontSize: screen459 ? "1.35rem" : "1rem" }}>
          <span className='text-muted fw-bold me-2' style={{ margin: screen459 ? "" : "0 auto" }}>{new Date().getFullYear()} &copy;</span>
          {!screen459 && <br />}
          <a
            href='https://adriapool.net/'
            className='text-gray-800 text-hover-primary'
            target='_blank'
            rel='noopener noreferrer'
          >
            Adria Stake Pool
          </a>
          <img src={pool} alt="" width={60} height={60} style={{ marginLeft: screen459 ? "-0.35rem" : "0.325rem", marginTop: screen459 ? "-1.25rem" : "-0.145rem", width: screen459 ? 60 : 33, height: screen459 ? 60 : 33 }} />
          {screen459 && <span style={{ fontSize: "2.35rem" }}> | </span>}
          {!screen459 && <br />}
          <a
            href='https://cardano.fans/'
            className='text-gray-800 text-hover-primary ml-2'
            target='_blank'
            rel='noopener noreferrer'
          >
            CRFA Stake Pool
          </a>
          <img src={circle} alt="" style={{ marginLeft: screen459 ? "0.625rem" : "1rem", marginTop: screen459 ? "-1.425rem" : "-0.3rem", width: screen459 ? 33 : 20, height: screen459 ? 33 : 20 }} />
        </div>

        <div className={`d-flex align-items-center mr-1 mb-3 ${screen644 && `mt-3 mb-0`}`}>
          <a target="_blank" className="ml-1" href="https://t.co/o3MYzdlb5E"> <img src={discord} style={{ width: "27px", height: "21px" }} /> </a>
          <a target="_blank" className="ml-5" href="https://twitter.com/dappsoncardano"> <img src={twitter} style={{ width: "28px", height: "22px" }} /> </a>
          <a target="_blank" className="ml-2" href="https://t.me/+C_qqAkWTBoYyYzhi"> <img src={telegram} style={{ width: "40px", height: "38px" }} /> </a>
          <a target="_blank" className="ml-3" href="https://github.com/Cardano-Fans/crfa-offchain-data-registry"> <img src={github} style={{ width: "25px", height: "25px" }} /> </a>
        </div>
      </div>

    </div>
  )
}

export { Footer }

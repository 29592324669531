import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { Overview } from '../../app/modules/profile/components/Overview';
import { AccountHeader } from '../../app/modules/accounts/AccountHeader';
import advertisement from '../../_metronic/assets/ad_adria.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import About from '../pages/About';
import Advertise from '../pages/Advertise';


const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const screen1200 = useMediaQuery('(min-width:1200px)');
  const screen824 = useMediaQuery('(min-width:824px)');
  const screen784 = useMediaQuery('(min-width:784px)');
  const screen736 = useMediaQuery('(min-width:736px)');
  const screen700 = useMediaQuery('(min-width:700px)');
  const screen425 = useMediaQuery('(min-width:425px)');
  const screen390 = useMediaQuery('(min-width:390px)');
  const screen349 = useMediaQuery('(min-width:349px)');
  const screen312 = useMediaQuery('(min-width:312px)');

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}

        <Route path='about' element={<About />} />
        <Route path='advertise' element={<Advertise />} />

        <Route path='/' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='/:overview' element={
        <>
          <AccountHeader />
          <div className={`flex justify-center ${screen1200 && `-mt-6`}`}>
            {
              screen700 ? (
                <a href="https://cexplorer.io/pool/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr" target="_blank">
                  <img src={advertisement} alt="advertisement" style={{ width: screen736 ? (screen784 ? (screen824 ? (screen1200 ? "600px" : "421px") : "371px") : "340px") : "301px", paddingRight: "1rem" }} />
                </a>
              ) : (
                <a href="https://cexplorer.io/pool/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr" target="_blank" className="w-full mb-12 d-flex justify-content-center" style={{ marginTop: "-7px", marginLeft: "2px" }}>
                  <img src={advertisement} alt="advertisement" style={{ width: screen312 ? (screen349 ? (screen390 ? (!screen425 ? "310px" : "350px") : "280px") : "240px") : "210px", paddingRight: "1rem" }} />
                </a>
              )
            }
          </div>
          <div className={`${screen700 ? `-mt-6` : `-mt-20`}`}>
            <Overview />
          </div>
        </>
        }/>
        <Route path='/analytics/globalstats' element={
        <>
          <AccountHeader />
          <div className={`flex justify-center ${screen1200 && `-mt-6`}`}>
            {
              screen700 ? (
                <a href="https://cexplorer.io/pool/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr" target="_blank">
                  <img src={advertisement} alt="advertisement" style={{ width: screen736 ? (screen784 ? (screen824 ? (screen1200 ? "600px" : "421px") : "371px") : "340px") : "301px", paddingRight: "1rem" }} />
                </a>
              ) : (
                <a href="https://cexplorer.io/pool/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr" target="_blank" className="w-full mb-12 d-flex justify-content-center" style={{ marginTop: "-7px", marginLeft: "2px" }}>
                  <img src={advertisement} alt="advertisement" style={{ width: screen312 ? (screen349 ? (screen390 ? (!screen425 ? "310px" : "350px") : "280px") : "240px") : "210px", paddingRight: "1rem" }} />
                </a>
              )
            }
          </div>
          <div className={`${screen700 ? `-mt-6` : `-mt-20`}`}>
            <Overview />
          </div>
        </>
        }/>
        <Route path='/releases/:releases' element={<DashboardWrapper />} />
        <Route path='/releases/:releases/:versions' element={<DashboardWrapper />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
//initialState
export const initialState: {
    openPopup: boolean;
    closingProcessPopup: boolean;
} = {
    openPopup: false,
    closingProcessPopup: false
}


//actionTypes
const SET_OPENPOPUP: string = "SET_OPENPOPUP";
const SET_CLOSINGPROCESS: string = "SET_CLOSINGPROCESS";


//actions
export const setting_OPENPOPUP = (bool: boolean) => {
    return {
        type: SET_OPENPOPUP,
        payload: bool
    }
}
export const setting_CLOSINGPROCESS = (bool: boolean) => {
    return {
        type: SET_CLOSINGPROCESS,
        payload: bool
    }
}


//reducer
export function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_OPENPOPUP:
            return {
                ...state,
                openPopup: action.payload
            }

        case SET_CLOSINGPROCESS:
            return {
                ...state,
                closingProcessPopup: action.payload
            }

        default:
            return state;
    }
}
import clsx from 'clsx'
import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
import { ConnectWallet } from "../../../../app/wallet/features/wallet/components/ConnectWallet";
import { SelectWalletModal } from "../../../../app/wallet/features/wallet/components/SelectWalletModal";
import { useRestoreWallet } from "../../../../app/wallet/features/wallet";
import { Toaster } from 'react-hot-toast'
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import useMediaQuery from '@mui/material/useMediaQuery';
import { discordWhite } from '../../images';


const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const styles = {
  wallet: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
    border: 'none',
    borderRadius: "50px",
    padding: "5px 30px",
    fontSize: "16px",
    cursor: "pointer"
  },
}

const Topbar: FC = () => {
  const screen380 = useMediaQuery('(min-width:380px)');
  const screen850 = useMediaQuery('(min-width:850px)');
  const screen334 = useMediaQuery('(min-width:334px)');


  useRestoreWallet()
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <SelectWalletModal />
      <div className={`d-flex align-items-center ${screen380 ? `mr-6` : `mr-4`}`}>
        <div className={`bg-transparent ${screen850 ? `p-3 pt-2 pb-2` : `mr-4 mb-1`}`}>
          <a className="d-flex align-items-center" target="_blank" href="https://cexplorer.io/pool/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr"><img src="//img.cexplorer.io/d/c/e/1/a/pool1we9umarzn0l6jp8mcm98y28lxuuzcurzpjldnjwtgdhgw068mnr.png" className="rounded-circle" height={screen380 ? (screen850 ? "36" : "29") : "7"} width={screen380 ? (screen850 ? "36" : "29") : "21"} alt="" /> <span className={`${screen850 ? `ml-3` : `hidden`} text-white`}>[ADRIA] Adria StakePool</span> </a>
        </div>
        <div className={`bg-transparent ${screen850 ? `ml-3 mr-10 p-3 pt-2 pb-2` : `mr-3 mb-1`}`}>
          <a className="d-flex align-items-center" target="_blank" href="https://cexplorer.io/pool/pool1d3gckjrphwytzw2uavgkxskwe08msumzsfj4lxnpcnpks3zjml3"><img src="//img.cexplorer.io/6/2/9/e/9/pool1d3gckjrphwytzw2uavgkxskwe08msumzsfj4lxnpcnpks3zjml3.png" className="rounded-circle" height={screen380 ? (screen850 ? "36" : "29") : "7"} width={screen380 ? (screen850 ? "36" : "29") : "22"} alt="" /> <span className={`${screen850 ? `ml-3` : `hidden`} text-white`}>[CRFA] Cardano Fans</span> </a>
        </div>
        <div className={`flex ${screen334?`flex-row`:`flex-col`}`}>
        <div className={`flex items-center ${!screen334&&`ml-2`}`}>
            <a target="_blank" className={`${screen380 ? `ml-1 mb-0.5` : `ml-1 mb-0.5`}`} href="https://t.co/o3MYzdlb5E"> <img src={discordWhite} alt="" style={{ width: screen380 ? "27px" : "20px", height: screen380 ? "22px" : "15px" }} /> </a>
            <a target="_blank" className={`${screen380 ? `ml-5` : `ml-4`}`} href="https://twitter.com/dappsoncardano"> <TwitterIcon style={{ fill: "white" }} fontSize={screen380 ? "large" : "medium"} /> </a>
          </div>
          <div className={`flex items-center ${!screen334&&`mt-1 ml-2`}`}>
            <a target="_blank" className={`${screen380 ? `ml-3` : `ml-2`}`} href="https://t.me/+C_qqAkWTBoYyYzhi"> <TelegramIcon style={{ fill: "white" }} fontSize={screen380 ? "large" : "medium"} /> </a>
            <a target="_blank" className={`${screen380 ? `ml-4` : `ml-3`}`} href="https://github.com/Cardano-Fans/crfa-offchain-data-registry"> <GitHubIcon style={{ fill: "white" }} fontSize={screen380 ? "large" : "medium"} /> </a>
          </div>
        </div>
      </div>
      <ConnectWallet />
      <Toaster />
    </div >
  )
}

export { Topbar }
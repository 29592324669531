import React, { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const UsersListFilter = (props: any) => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  console.log(props)

  const resetData = () => {
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {
    updateState({
      filter: { role, last_login: lastLogin },
      ...initialQueryState,
    })
  }


  const screen349 = useMediaQuery('(min-width:349px)');
  const screen312 = useMediaQuery('(min-width:312px)');


  const [openModal, setOpenModal] = useState<boolean>(false);


  return (
    <>
      {
        !screen312 && (
          <IconButton
            type='button'
            disabled={isLoading}
            onClick={() => setOpenModal(true)}
            className={`btn btn-light-primary ${!screen349 && `btn-sm`}`}
            style={{ width: "1rem" }}
          >
            <FilterAltIcon />
          </IconButton>
        )
      }
      {
        screen312 && (
          <button
            type='button'
            disabled={isLoading}
            onClick={() => setOpenModal(true)}
            className={`btn btn-light-primary ${screen349 ? `me-3` : `btn-sm`}`}
            style={{ fontSize: screen349 ? "" : "10px" }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <div className='flex'>
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Filter
            </div>
          </button>
        )
      }

      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </Modal.Header>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Category:</label>
            <Form.Select
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value=''></option>
              {
                props.values.map((value: any, index: any) => { return <option key={index} value={value}>{value}</option> })
              }
            </Form.Select>
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <button
            disabled={isLoading}
            onClick={() => { resetData(); props.filter(""); setRole(""); setOpenModal(false); }}
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
          >
            Reset
          </button>
          <button
            disabled={isLoading}
            onClick={() => { filterData(); props.filter(role); setOpenModal(false); }}
            className='btn btn-primary fw-bold px-6'
          >
            Apply
          </button>
        </div>
      </Modal>
    </>
  )
}

export { UsersListFilter }

import React from 'react'
// import NextImage from 'next/image'
import { useAtom } from 'jotai'
import { Modal } from '../../../shared/Modal'
import { config } from '../../../shared/config'
import { selectWalletModalAtom } from '../atoms'
import { useWallet } from '../useWallet'
import eternl from './wallet-logos/eternl.png'
import flint from './wallet-logos/flint.svg'
import nami from './wallet-logos/nami.svg'
import yoroi from './wallet-logos/yoroi.png'

export const SelectWalletModal: React.FC = () => {
  const [isOpen, setIsOpen] = useAtom(selectWalletModalAtom)
  const { connectWallet } = useWallet()

  if (!isOpen) {
    return null
  }

  if(isOpen){
    console.log("Hi")
  }

  return (
    <Modal
      title="Select Wallet"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <div className="grid grid-cols-2 gap-6">
        {Object.values(config.wallets).map((wallet) => (
          <button
            key={wallet.name}
            className="p-3 bg-blue-200 hover:bg-blue-300 rounded-lg transition-all"
            onClick={() => {
              connectWallet(wallet.walletKey)
              setIsOpen(false)
            }}
          >
            <img
              height={80}
              width={80}
              src={wallet.logo === "eternl" ? eternl : wallet.logo === "flint" ? flint : wallet.logo === "nami" ? nami : yoroi}
              alt=""
              style={{margin: "5px auto",}}
            />

            <div className="text-slate-100 text-lg">{wallet.name}</div>
          </button>
        ))}
      </div>
    </Modal>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { AiOutlineTwitter } from 'react-icons/ai'
import { TbWorld } from 'react-icons/tb'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import openSource from "../../../layout/images/open-source.png";
import audit from "../../../layout/images/audit.png";
import { UsersListFilter } from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter';
import { UsersListSearchComponent } from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListSearchComponent'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import PestControlIcon from '@mui/icons-material/PestControl';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate, useParams, UNSAFE_NavigationContext } from 'react-router-dom'
import { History, Update } from "history";
import Modal from 'react-bootstrap/Modal';
import banner from '../../../assets/banner.gif';
import crfaad from '../../../assets/ad_crfa.png';
import advertisement from '../../../assets/ad_adria.png';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
//CONTEXT-API
import { useStateValue } from '../../../../StateContext';
import { setting_OPENPOPUP, setting_CLOSINGPROCESS } from '../../../../contextapi';



const modifiedArray = (arr: any[]) => {
  return arr.map((item: any) => {
    let obj = {};


    if (
      (
        item
          ?.epochLevelData
          ?.lastEpochDeltaWithOnlyClosedEpochs
          ?.activityDiffPerc
      )
    ) {
      obj = {
        ...obj,
        lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: item?.epochLevelData?.lastEpochDeltaWithOnlyClosedEpochs?.activityDiffPerc
      }
    }
    else {
      obj = {
        ...obj,
        lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: 0
      }
    }
    if (
      (
        item
          ?.epochLevelData
          ?.lastMonthDeltaWithOnlyClosedEpochs
          ?.activityDiffPerc
      )
    ) {
      obj = {
        ...obj,
        lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: item?.epochLevelData?.lastMonthDeltaWithOnlyClosedEpochs?.activityDiffPerc
      }
    }
    else {
      obj = {
        ...obj,
        lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: 0
      }
    }
    if (
      (
        item
          ?.epochLevelData
          ?.lastQuarterDeltaWithOnlyClosedEpochs
          ?.activityDiffPerc
      )
    ) {
      obj = {
        ...obj,
        lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: item?.epochLevelData?.lastQuarterDeltaWithOnlyClosedEpochs?.activityDiffPerc
      }
    }
    else {
      obj = {
        ...obj,
        lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: 0
      }
    }


    return {
      ...item,
      ...obj
    }
  });
}


const useStyles = makeStyles({
  heading: {
    textAlign: "center",
    fontSize: "1rem !important",
    color: "#90a5db",
    padding: "1rem",
    marginLeft: "0.135rem",
    marginRight: "0.135rem",
    marginTop: "0.835rem",
    cursor: "pointer"
  },
  category: {
    color: "#4d4949",
    padding: "0.55rem",
    borderRadius: "4.5px"
  },
  subcategory: {
    backgroundColor: "#a1b4e6",
    color: "white !important",
    padding: "2px",
    paddingRight: "8.25px",
    paddingLeft: "8.25px",
    borderRadius: "4.5px",
    // margin: "0 auto",
    width: "fit-content",
    height: "fit-content"
  },
  highlighted: {
    color: "blue !important"
  },
  paper: {
    minWidth: "42.9vh",
    maxWidth: "42.9vh",
    minHeight: "27.9vh",
    maxHeight: "27.9vh",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});



type Props = {
  className: string
}
const TablesWidget5: React.FC<Props> = ({ className }) => {
  const keys = [
    'All Time',
    '18 epochs',
    '6 epochs',
    "Last epoch"
  ];
  const [active, setActive] = useState<number>(2);

  const [{ openPopup }, dispatch] = useStateValue();

  const classes = useStyles();
  const navigate = useNavigate();
  const param = useParams();

  const useBackListener = (callback: (...args: any) => void) => {
    const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

    useEffect(() => {
      const listener = ({ location, action }: Update) => {
        if (action === "POP") {
          callback({ location, action });
        }
      };

      const unlisten = navigator.listen(listener);
      return unlisten;
    }, [callback, navigator]);
  }
  //TRACK BROWSER BACK BUTTON
  useBackListener(() => {
    if (
      findRelease
        .length > 0
      &&
      Object.keys(byReleaseKey)
        .length > 0
    ) {
      dispatch(setting_OPENPOPUP(false));
      dispatch(setting_CLOSINGPROCESS(true));
      navigate(`/releases/${param.releases}`);
      /*RESET*/
      setByReleaseKey([]);
      setSelected_byReleaseKey("");
    }
    else if (
      findRelease
        .length > 0
    ) {
      navigate("/");
      dispatch(setting_OPENPOPUP(false));
      dispatch(setting_CLOSINGPROCESS(false));
      /*RESET*/
      setFindRelease([]);
      setSelected_findRelease("");
    }
  });


  const [tableData, setTableData] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);


  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (
      (param.releases) ||
      (param.releases && param.versions)
    ) {
      setIsLoading(true);
    }

    var data: any[] = []
    axios.get("/dapps/list-dapps")
      .then((res) => {
        setTableData(res.data);
        for (var i = 0; i < res.data.length; i++) {
          data.push(res.data[i].category)
        }
        setCategories(Array.from(new Set(data)));

        // setSORT_TransactionCount(true);
        // setSORT_Active("transactionsCount")
        // setSORT_TransactionCount(false);
        // setSORT_Active("transactionsCount")
      });
  }, []);


  const modifiedData18Epochs = (arr: any) => {
    return arr.map((item: any) => {
      const epochLevelData = item.epochLevelData;
      const lastQuarterDeltaWithOnlyClosedEpochs = epochLevelData?.lastQuarterDeltaWithOnlyClosedEpochs;

      let volume = lastQuarterDeltaWithOnlyClosedEpochs?.snapshot?.volume;
      let trxCount = lastQuarterDeltaWithOnlyClosedEpochs?.snapshot?.trxCount;
      let uniqueAccounts = lastQuarterDeltaWithOnlyClosedEpochs?.snapshot?.uniqueAccounts;

      return {
        ...item,
        /* overrides */
        volume,
        trxCount,
        uniqueAccounts
      }
    });
  }
  const modifiedData6Epochs = (arr: any) => {
    return arr.map((item: any) => {
      const epochLevelData = item.epochLevelData;
      const lastMonthDeltaWithOnlyClosedEpochs = epochLevelData?.lastMonthDeltaWithOnlyClosedEpochs;

      let volume = lastMonthDeltaWithOnlyClosedEpochs?.snapshot?.volume;
      let trxCount = lastMonthDeltaWithOnlyClosedEpochs?.snapshot?.trxCount;
      let uniqueAccounts = lastMonthDeltaWithOnlyClosedEpochs?.snapshot?.uniqueAccounts;

      return {
        ...item,
        /* overrides */
        volume,
        trxCount,
        uniqueAccounts
      }
    });
  }
  const modifiedDataLastEpoch = (arr: any) => {
    return arr.map((item: any) => {
      const epochLevelData = item.epochLevelData;
      const lastEpochDeltaWithOnlyClosedEpochs = epochLevelData?.lastEpochDeltaWithOnlyClosedEpochs;

      let volume = lastEpochDeltaWithOnlyClosedEpochs?.snapshot?.volume;
      let trxCount = lastEpochDeltaWithOnlyClosedEpochs?.snapshot?.trxCount;
      let uniqueAccounts = lastEpochDeltaWithOnlyClosedEpochs?.snapshot?.uniqueAccounts;

      return {
        ...item,
        /* overrides */
        volume,
        trxCount,
        uniqueAccounts
      }
    });
  }


  const [searchData, setSearchData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<any>("");


  //***************************************************************************/
  const [SORT_name, setSORT_name] = useState(false);
  const [SORT_category, setSORT_category] = useState(false);
  const [SORT_transactionCount, setSORT_TransactionCount] = useState(false);
  const [SORT_scriptsLocked, setSORT_ScriptsLocked] = useState(false);
  const [SORT_volume, setSORT_Volume] = useState(false);
  const [SORT_uniqueAccounts, setSORT_UniqueAccounts] = useState(false);
  const [SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc, setSORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc] = useState(false);
  const [SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc, setSORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc] = useState(false);
  const [SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc, setSORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc] = useState(false);
  const [SORT_active, setSORT_Active] = useState("");


  useEffect(() => {
    setSORT_Active("name")
    if (SORT_name) {
      // if (active === 0) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.name)
            .sort();

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.name)
            .sort();

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.name)
      //       .sort();

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.name)
      //       .sort();

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.name)
            .sort();

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.name)
            .sort();

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.name)
      //       .sort();

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.name)
      //       .sort();

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_name) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.name)
            .sort()
            .reverse();

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.name)
            .sort()
            .reverse();

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.name)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.name)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.name)
            .sort()
            .reverse();

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.name)
            .sort()
            .reverse();

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.name)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.name)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.name > b.name) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, name: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_name]);


  useEffect(() => {
    setSORT_Active("category")
    if (SORT_category) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.category)
            .sort();

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.category)
            .sort();

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.category)
      //       .sort();

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.category)
      //       .sort();

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.category)
            .sort();

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.category)
            .sort();

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.category)
      //       .sort();

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.category)
      //       .sort();

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_category) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.category)
            .sort()
            .reverse();

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.category)
            .sort()
            .reverse();

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.category)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.category)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.category)
            .sort()
            .reverse();

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.category)
            .sort()
            .reverse();

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.category)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.category)
      //       .sort()
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.category > b.category) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, category: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_category]);


  useEffect(() => {
    setSORT_Active("scriptsLocked")
    if (SORT_scriptsLocked) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b);

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b);

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b);

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b);

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b);

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b);

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b);

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b);

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_scriptsLocked) {
      if (active !== 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b)
            .reverse();

          setTableData(
            filterData
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b)
            .reverse();

          setTableData(
            tableData
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b)
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b)
      //       .reverse();

      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        if (filterData.length > 0) {
          const sorted = filterData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b)
            .reverse();

          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
        else {
          const sorted = tableData
            .map((item: any) => item.scriptsLocked)
            .sort((a: number, b: number) => a - b)
            .reverse();

          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   if (filterData.length > 0) {
      //     const sorted = filterData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b)
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      //   else {
      //     const sorted = tableData
      //       .map((item: any) => item.scriptsLocked)
      //       .sort((a: number, b: number) => a - b)
      //       .reverse();

      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_scriptsLocked]);


  useEffect(() => {
    setSORT_Active("volume")
    if (SORT_volume) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.volume)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.volume)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .map((item: any) => item.volume)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = tableData
      //     .map((item: any) => item.volume)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_volume) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.volume)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.volume)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .map((item: any) => item.volume)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = tableData
      //     .map((item: any) => item.volume)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_volume]);


  useEffect(() => {
    setSORT_Active("uniqueAccounts")
    if (SORT_uniqueAccounts) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.uniqueAccounts)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
      else if (active === 3) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedDataLastEpoch(filterData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedDataLastEpoch(tableData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
    }

    else if (!SORT_uniqueAccounts) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.uniqueAccounts)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
      else if (active === 3) {
        const sorted = tableData
          .map((item: any) => item.uniqueAccounts)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedDataLastEpoch(filterData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedDataLastEpoch(tableData)
              .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
          );
        }
      }
    }
  }, [SORT_uniqueAccounts]);


  useEffect(() => {
    setSORT_Active("lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc")
    if (SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) {
      if (active === 0) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b);


        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);


      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b);


        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);


      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) {
      if (active === 0) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc]);


  useEffect(() => {
    setSORT_Active("lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc")
    if (SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) {
      if (active !== 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) {
      if (active !== 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(tableData)
          .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(tableData)
      //     .map((item: any) => item.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc]);


  useEffect(() => {
    setSORT_Active("lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc")
    if (SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) {
      if (active !== 2) {
        const sorted = modifiedArray(filterData)
          .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(filterData)
      //     .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(filterData)
          .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(filterData)
      //     .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) {
      if (active !== 2) {
        const sorted = modifiedArray(filterData)
          .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(filterData)
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activlastQuarterDeltaWithOnlyClosedEpochs_activityDiffPercityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(tableData)
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = modifiedArray(filterData)
      //     .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(filterData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activlastQuarterDeltaWithOnlyClosedEpochs_activityDiffPercityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedData18Epochs(tableData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = modifiedArray(filterData)
          .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedArray(modifiedData6Epochs(filterData))
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activlastQuarterDeltaWithOnlyClosedEpochs_activityDiffPercityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedArray(modifiedData6Epochs(tableData))
              .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = modifiedArray(filterData)
      //     .map((item: any) => item.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(filterData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastMonthDeltaWithOnlyClosedEpochsdef_activlastQuarterDeltaWithOnlyClosedEpochs_activityDiffPercityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedArray(modifiedDataLastEpoch(tableData))
      //         .sort((a: any, b: any) => (a.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc > b.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc]);


  useEffect(() => {
    setSORT_Active("transactionsCount")
    if (SORT_transactionCount) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.trxCount)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.trxCount)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .map((item: any) => item.trxCount)
          .sort((a: number, b: number) => a - b);

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = tableData
      //     .map((item: any) => item.trxCount)
      //     .sort((a: number, b: number) => a - b);

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      // }
    }

    else if (!SORT_transactionCount) {
      if (active !== 2) {
        const sorted = tableData
          .map((item: any) => item.trxCount)
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            filterData
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
        else {
          setTableData(
            tableData
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
      }
      // else if (active === 1) {
      //   const sorted = tableData
      //     .map((item: any) => item.trxCount)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedData18Epochs(filterData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedData18Epochs(tableData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      // }
      else if (active === 2) {
        const sorted = tableData
          .sort((a: number, b: number) => a - b)
          .reverse();

        if (filterData.length > 0) {
          setTableData(
            modifiedData6Epochs(filterData)
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
        else {
          setTableData(
            modifiedData6Epochs(tableData)
              .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
              .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
          );
        }
      }
      // else if (active === 3) {
      //   const sorted = tableData
      //     .map((item: any) => item.trxCount)
      //     .sort((a: number, b: number) => a - b)
      //     .reverse();

      //   if (filterData.length > 0) {
      //     setTableData(
      //       modifiedDataLastEpoch(filterData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      //   else {
      //     setTableData(
      //       modifiedDataLastEpoch(tableData)
      //         .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
      //         .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      //     );
      //   }
      // }
    }
  }, [SORT_transactionCount]);
  //***************************************************************************/


  function convertNumber(labelValue: number) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

          : Math.abs(Number(labelValue));

  }

  const userInput = (input: string) => {
    let data = []
    setSearchInput(input)
    if (input !== "") {
      for (var i = 0; i < tableData.length; i++) {
        if ((tableData[i].fullName && tableData[i].fullName.toLowerCase().includes(input.toLowerCase())) || tableData[i].name.toLowerCase().includes(input.toLowerCase())) {
          data.push(tableData[i])
        }
      }
    }
    setFilterData([])
    setSearchData(data)
  }

  const filter = (value: string) => {
    let data = []
    if (value !== "") {
      for (var i = 0; i < tableData.length; i++) {
        if (value === tableData[i].category) {
          data.push(tableData[i])
        }
      }
      setSearchData([])
      setFilterData(data)
    }
    else {
      var data_: any[] = []
      axios.get("/dapps/list-dapps")
        .then((res) => {
          setTableData(res.data);
          for (var i = 0; i < res.data.length; i++) {
            data_.push(res.data[i].category)
          }
          setCategories(Array.from(new Set(data_)))
        });
      setFilterData([]);
    }
  }

  const getData = () => {
    if (active === 0) {
      if ((searchData && searchData[0]) || searchInput) {
        return searchData;
      }

      if (filterData && filterData[0]) {
        return filterData;
      }

      return tableData;
    }

    else if (active === 1) {
      if ((searchData && searchData[0]) || searchInput) {
        return modifiedData18Epochs(searchData);
      }

      if (filterData && filterData[0]) {
        return modifiedData18Epochs(filterData);
      }

      return modifiedData18Epochs(tableData);
    }

    else if (active === 2) {
      if ((searchData && searchData[0]) || searchInput) {
        return modifiedData6Epochs(searchData);
      }

      if (filterData && filterData[0]) {
        return modifiedData6Epochs(filterData);
      }

      return modifiedData6Epochs(tableData);
    }

    else if (active === 3) {
      if ((searchData && searchData[0]) || searchInput) {
        return modifiedDataLastEpoch(searchData);
      }

      if (filterData && filterData[0]) {
        return modifiedDataLastEpoch(filterData);
      }

      return modifiedDataLastEpoch(tableData);
    }
  }
  //***************************************************************************/


  const [findRelease, setFindRelease] = useState<any>([]);
  const [selected_findRelease, setSelected_findRelease] = useState<string>("");
  const [byReleaseKey, setByReleaseKey] = useState<any>({});
  const [selected_byReleaseKey, setSelected_byReleaseKey] = useState<any>("");


  //***************************************************************************/
  const [SORT_NESTED_name, setSORT_NESTED_name] = useState(false);
  const [SORT_NESTED_version, setSORT_NESTED_version] = useState(false);
  const [SORT_NESTED_transactionCount, setSORT_NESTED_TransactionCount] = useState(false);
  const [SORT_NESTED_scriptsLocked, setSORT_NESTED_ScriptsLocked] = useState(false);
  const [SORT_NESTED_scriptInvocCount, setSORT_NESTED_ScriptInvocCount] = useState(false);
  const [SORT_NESTED_volume, setSORT_NESTED_Volume] = useState(false);
  const [SORT_NESTED_uniqueAccounts, setSORT_NESTED_UniqueAccounts] = useState(false);
  const [SORT_NESTED_active, setSORT_NESTED_Active] = useState("");


  useEffect(() => {
    setSORT_NESTED_Active("name")
    if (SORT_NESTED_name) {
      const sorted = findRelease
        .map((item: any) => item?.fullName ? item.fullName : item?.name)
        .sort();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a?.fullName ? a.fullName : a?.name > b?.fullName ? b.fullName : b?.name) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, name: sorted[i], /*no risk*/ fullName: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_name) {
      const sorted = findRelease
        .map((item: any) => item?.fullName ? item.fullName : item?.name)
        .sort()
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a?.fullName ? a.fullName : a?.name > b?.fullName ? b.fullName : b?.name) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, name: sorted[i], /*no risk*/ fullName: sorted[i] } })
      );
    }
  }, [SORT_NESTED_name]);


  useEffect(() => {
    setSORT_NESTED_Active("scriptsLocked")
    if (SORT_NESTED_scriptsLocked) {
      const sorted = findRelease
        .map((item: any) => item.scriptsLocked)
        .sort((a: number, b: number) => a - b);

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_scriptsLocked) {
      const sorted = findRelease
        .map((item: any) => item.scriptsLocked)
        .sort((a: number, b: number) => a - b)
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      );
    }
  }, [SORT_NESTED_scriptsLocked]);


  useEffect(() => {
    setSORT_NESTED_Active("scriptInvocationsCount")
    if (SORT_NESTED_scriptInvocCount) {
      const sorted = findRelease
        .map((item: any) => item.scriptInvocationsCount)
        .sort((a: number, b: number) => a - b);

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.scriptInvocationsCount > b.scriptInvocationsCount) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, scriptInvocationsCount: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_scriptInvocCount) {
      const sorted = findRelease
        .map((item: any) => item.scriptInvocationsCount)
        .sort((a: number, b: number) => a - b)
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.scriptInvocationsCount > b.scriptInvocationsCount) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, scriptInvocationsCount: sorted[i] } })
      );
    }
  }, [SORT_NESTED_scriptInvocCount]);


  useEffect(() => {
    setSORT_NESTED_Active("transactionsCount")
    if (SORT_NESTED_transactionCount) {
      const sorted = findRelease
        .map((item: any) => Number(item.transactionsCount))
        .sort((a: number, b: number) => a - b);

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.transactionsCount > b.transactionsCount) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, transactionsCount: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_transactionCount) {
      const sorted = findRelease
        .map((item: any) => Number(item.transactionsCount))
        .sort((a: number, b: number) => a - b)
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.transactionsCount > b.transactionsCount) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, transactionsCount: sorted[i] } })
      );
    }
  }, [SORT_NESTED_transactionCount]);


  useEffect(() => {
    setSORT_NESTED_Active("volume")
    if (SORT_NESTED_volume) {
      const sorted = findRelease
        .map((item: any) => Number(item.volume))
        .sort((a: number, b: number) => a - b);

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_volume) {
      const sorted = findRelease
        .map((item: any) => Number(item.volume))
        .sort((a: number, b: number) => a - b)
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      );
    }
  }, [SORT_NESTED_volume]);


  useEffect(() => {
    setSORT_NESTED_Active("uniqueAccounts")
    if (SORT_NESTED_uniqueAccounts) {
      const sorted = findRelease
        .map((item: any) => Number(item.uniqueAccounts))
        .sort((a: number, b: number) => a - b);

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_uniqueAccounts) {
      const sorted = findRelease
        .map((item: any) => Number(item.uniqueAccounts))
        .sort((a: number, b: number) => a - b)
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      );
    }
  }, [SORT_NESTED_uniqueAccounts]);


  useEffect(() => {
    setSORT_NESTED_Active("version")
    if (SORT_NESTED_version) {
      const sorted = findRelease
        .map((item: any) => item.releaseNumber)
        .sort();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.releaseNumber > b.releaseNumber) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, releaseNumber: sorted[i] } })
      );
    }

    else if (!SORT_NESTED_version) {
      const sorted = findRelease
        .map((item: any) => item.releaseNumber)
        .sort()
        .reverse();

      setFindRelease(
        findRelease
          .sort((a: any, b: any) => (a.releaseNumber > b.releaseNumber) ? -1 : 1)
          .map((item: any, i: number) => { return { ...item, releaseNumber: sorted[i] } })
      );
    }
  }, [SORT_NESTED_version]);
  //***************************************************************************/


  //***************************************************************************/
  const [SORT_NESTED_INNER_transactionCount, setSORT_NESTED_INNER_TransactionCount] = useState(false);
  const [SORT_NESTED_INNER_volume, setSORT_NESTED_INNER_Volume] = useState(false);
  const [SORT_NESTED_INNER_scriptsLocked, setSORT_NESTED_INNER_ScriptsLocked] = useState(false);
  const [SORT_NESTED_INNER_scriptInvocCount, setSORT_NESTED_INNER_ScriptInvocCount] = useState(false);
  const [SORT_NESTED_INNER_uniqueAccounts, setSORT_NESTED_INNER_UniqueAccounts] = useState(false);
  const [SORT_NESTED_INNER_active, setSORT_NESTED_INNER_Active] = useState("");


  useEffect(() => {
    setSORT_NESTED_INNER_Active("scriptsLocked")
    var removedNAN_scriptsLocked = byReleaseKey?.scripts?.map((item: any) => {
      if (item.scriptsLocked) {
        return item;
      }

      return { ...item, scriptsLocked: 0 }
    });

    if (SORT_NESTED_INNER_scriptsLocked) {
      const sorted = removedNAN_scriptsLocked
        .map((item: any) => item.scriptsLocked)
        .sort((a: number, b: number) => a - b);

      setByReleaseKey({
        ...byReleaseKey,
        scripts: removedNAN_scriptsLocked
          .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
      });
    }

    else if (!SORT_NESTED_INNER_scriptsLocked) {
      if (
        Object.keys(byReleaseKey)
          .length > 0
      ) {
        const sorted = removedNAN_scriptsLocked
          .map((item: any) => item.scriptsLocked)
          .sort((a: number, b: number) => a - b)
          .reverse();

        setByReleaseKey({
          ...byReleaseKey,
          scripts: removedNAN_scriptsLocked
            .sort((a: any, b: any) => (a.scriptsLocked > b.scriptsLocked) ? -1 : 1)
            .map((item: any, i: number) => { return { ...item, scriptsLocked: sorted[i] } })
        });
      }
    }
  }, [SORT_NESTED_INNER_scriptsLocked]);


  useEffect(() => {
    setSORT_NESTED_INNER_Active("transactionsCount")
    if (SORT_NESTED_INNER_transactionCount) {
      const sorted = byReleaseKey?.scripts
        .map((item: any) => Number(item.trxCount))
        .sort((a: number, b: number) => a - b);

      setByReleaseKey({
        ...byReleaseKey,
        scripts: byReleaseKey?.scripts
          .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
      });
    }

    else if (!SORT_NESTED_INNER_transactionCount) {
      if (
        Object.keys(byReleaseKey)
          .length > 0
      ) {
        const sorted = byReleaseKey?.scripts
          .map((item: any) => Number(item.trxCount))
          .sort((a: number, b: number) => a - b)
          .reverse();

        setByReleaseKey({
          ...byReleaseKey,
          scripts: byReleaseKey?.scripts
            .sort((a: any, b: any) => (a.trxCount > b.trxCount) ? -1 : 1)
            .map((item: any, i: number) => { return { ...item, trxCount: sorted[i] } })
        });
      }
    }
  }, [SORT_NESTED_INNER_transactionCount]);


  useEffect(() => {
    setSORT_NESTED_INNER_Active("volume")
    if (SORT_NESTED_INNER_volume) {
      const sorted = byReleaseKey?.scripts
        .map((item: any) => Number(item.volume))
        .sort((a: number, b: number) => a - b);

      setByReleaseKey({
        ...byReleaseKey,
        scripts: byReleaseKey?.scripts
          .sort((a: any, b: any) => (a.volume > b.volume) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
      });
    }

    else if (!SORT_NESTED_INNER_volume) {
      if (
        Object.keys(byReleaseKey)
          .length > 0
      ) {
        const sorted = byReleaseKey?.scripts
          .map((item: any) => Number(item.volume))
          .sort((a: number, b: number) => a - b)
          .reverse();

        setByReleaseKey({
          ...byReleaseKey,
          scripts: byReleaseKey?.scripts
            .sort((a: any, b: any) => (a.volume > b.volume) ? -1 : 1)
            .map((item: any, i: number) => { return { ...item, volume: sorted[i] } })
        });
      }
    }
  }, [SORT_NESTED_INNER_volume]);


  useEffect(() => {
    setSORT_NESTED_INNER_Active("uniqueAccounts")
    if (SORT_NESTED_INNER_volume) {
      const sorted = byReleaseKey?.scripts
        .map((item: any) => Number(item.uniqueAccounts))
        .sort((a: number, b: number) => a - b);

      setByReleaseKey({
        ...byReleaseKey,
        scripts: byReleaseKey?.scripts
          .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
      });
    }

    else if (!SORT_NESTED_INNER_uniqueAccounts) {
      if (
        Object.keys(byReleaseKey)
          .length > 0
      ) {
        const sorted = byReleaseKey?.scripts
          .map((item: any) => Number(item.uniqueAccounts))
          .sort((a: number, b: number) => a - b)
          .reverse();

        setByReleaseKey({
          ...byReleaseKey,
          scripts: byReleaseKey?.scripts
            .sort((a: any, b: any) => (a.uniqueAccounts > b.uniqueAccounts) ? -1 : 1)
            .map((item: any, i: number) => { return { ...item, uniqueAccounts: sorted[i] } })
        });
      }
    }
  }, [SORT_NESTED_INNER_uniqueAccounts]);


  useEffect(() => {
    setSORT_NESTED_INNER_Active("scriptInvocationsCount")
    if (SORT_NESTED_INNER_scriptInvocCount) {
      const sorted = byReleaseKey?.scripts
        .map((item: any) => item.scriptInvocationsCount)
        .sort((a: number, b: number) => a - b);

      setByReleaseKey({
        ...byReleaseKey,
        scripts: byReleaseKey?.scripts
          .sort((a: any, b: any) => (a.scriptInvocationsCount > b.scriptInvocationsCount) ? 1 : -1)
          .map((item: any, i: number) => { return { ...item, scriptInvocationsCount: sorted[i] } })
      });
    }

    else if (!SORT_NESTED_INNER_scriptInvocCount) {
      if (
        Object.keys(byReleaseKey)
          .length > 0
      ) {
        const sorted = byReleaseKey?.scripts
          .map((item: any) => item.scriptInvocationsCount)
          .sort((a: number, b: number) => a - b)
          .reverse();

        setByReleaseKey({
          ...byReleaseKey,
          scripts: byReleaseKey?.scripts
            .sort((a: any, b: any) => (a.scriptInvocationsCount > b.scriptInvocationsCount) ? -1 : 1)
            .map((item: any, i: number) => { return { ...item, scriptInvocationsCount: sorted[i] } })
        });
      }
    }
  }, [SORT_NESTED_INNER_scriptInvocCount]);
  //***************************************************************************/


  useEffect(() => {
    const fetch = async (item: any) => {
      await axios.get(`/dapps/find-release/${item?.id}`).then((res) => setFindRelease(res.data));
      setSelected_findRelease(item?.fullName ? item.fullName : item?.name);
    };

    if (
      tableData.length > 0
    ) {
      if (
        param.releases
      ) {
        const item = tableData.find((item: any) => item.name === param.releases);
        fetch(item);
      }
    }
  }, [tableData]);
  useEffect(() => {
    const fetch = async (item: any) => {
      await axios.get(`/dapps/by-release-key/${item?.key}`).then((res) => setByReleaseKey(res.data));
      setSelected_byReleaseKey(item?.fullName ? item.fullName : item?.name);
      dispatch(setting_OPENPOPUP(true));
      dispatch(setting_CLOSINGPROCESS(false));
    }

    if (
      findRelease.length > 0
    ) {
      if (
        param.releases && param.versions
      ) {
        const item = findRelease.find((item: any) => item.fullName === param.versions);
        fetch(item);
      }
    }

    setIsLoading(false);
  }, [findRelease]);


  function MyVerticallyCenteredModal(props: any) {
    return (
      <Modal
        {...props}
        size="lg"
        centered
        animation={false}
      >
        <div style={{ width: "100%" }} className="mb-6 d-flex justify-content-end">
          <IconButton
            size="medium"
            style={{ backgroundColor: "#1fa0c7", color: "white", marginBottom: "0.625rem" }}
            onClick={() => {
              if (
                findRelease
                  .length > 0
                &&
                Object.keys(byReleaseKey)
                  .length > 0
              ) {
                dispatch(setting_OPENPOPUP(false));
                dispatch(setting_CLOSINGPROCESS(true));
                navigate(`/releases/${param.releases}`);
                /*RESET*/
                setByReleaseKey([]);
                setSelected_byReleaseKey("");
              }
            }}>
            <CancelIcon fontSize="medium" />
          </IconButton>
        </div>

        <>
          <h1> Release </h1>
          <div>
            <table className="table bg-white">
              <thead>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th className="text-center fw-bold cursor-pointer py-5 px-9" style={{ color: "#a1b2e0" }} scope="col">Logo</th>
                  <th className="text-center fw-bold cursor-pointer py-5" style={{ color: "#a1b2e0" }} scope="col">Name</th>
                  {/* <th className="text-center fw-bold cursor-pointer py-5" style={{ color: "#a1b2e0" }} scope="col">Category</th>  */}
                  <th className="text-center fw-bold py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col">
                    <span>Transactions</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>
                  <th className="fw-bold cursor-pointer py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col">
                    <span>Volume</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>
                  <th className="text-center fw-bold cursor-pointer py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col">
                    Scripts Balance
                    <ArrowUpwardIcon fontSize="small" sx={{ marginRight: "-1.5px" }} />
                    <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "-1.5px" }} />
                  </th>
                  <th className="fw-bold cursor-pointer py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col">
                    <span>Unq. Accounts</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>

                  <th className="text-center fw-bold cursor-pointer py-5 px-10" style={{ color: "#a1b2e0" }} scope="col">Latest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="flex justify-center fw-bold cursor-pointer py-8" scope="row">
                    <img src={byReleaseKey.release?.icon} className='h-11 align-self-center' alt='icon' />
                  </th>

                  <td className="text-center fw-bold cursor-pointer py-8">
                    <span>
                      {byReleaseKey.release?.fullName ? byReleaseKey.release?.fullName : byReleaseKey.release?.name}
                    </span>
                    <div style={{ display: "flex", justifyContent: "center", margin: "0px auto", alignItems: "center" }}>
                      <span className={classnames('text-muted fw-bold d-block text-center', classes.subcategory)}>{byReleaseKey.release?.subCategory} </span>
                      {byReleaseKey.release?.lastVersionContractsAuditedLink || byReleaseKey.release?.lastVersionContractsOpenSourcedLink ? <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          {byReleaseKey.release?.lastVersionContractsAuditedLink && <img src={audit} className='h-50 align-self-center' alt='icon' title="Audited" />}
                          {byReleaseKey.release?.lastVersionContractsOpenSourcedLink && <img src={openSource} className='h-50 align-self-center' alt='icon' title="Open Source" />}
                        </span>
                      </div> : ""}
                    </div>
                  </td>

                  <td className="text-center fw-bold cursor-pointer py-8">
                    <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{byReleaseKey.release ? byReleaseKey.release?.transactionsCount : ""}</span>} style={{ cursor: "pointer" }}>
                      <span> {byReleaseKey.release ? convertNumber(Number(byReleaseKey.release?.transactionsCount)) : ""} </span>
                    </Tooltip>
                  </td>

                  <td className="text-center fw-bold cursor-pointer py-8">
                    <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{byReleaseKey.release ? byReleaseKey.release?.volume : ""}</span>} style={{ cursor: "pointer" }}>
                      <span> {byReleaseKey.release ? convertNumber(Number(byReleaseKey.release?.volume)) + "  ₳" : ""} </span>
                    </Tooltip>
                  </td>

                  <td className="text-center fw-bold cursor-pointer py-8">
                    <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{byReleaseKey.release ? byReleaseKey.release?.scriptsLocked + "  ₳" : ""}</span>} style={{ cursor: "pointer" }}>
                      <span> {byReleaseKey.release ? convertNumber(Number(byReleaseKey.release?.scriptsLocked)) + "  ₳" : ""} </span>
                    </Tooltip>
                  </td>
                  <td className="text-center fw-bold cursor-pointer py-8">
                    <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{byReleaseKey.release ? byReleaseKey.release?.uniqueAccounts : ""}</span>} style={{ cursor: "pointer" }}>
                      <span> {byReleaseKey.release ? convertNumber(Number(byReleaseKey.release?.uniqueAccounts)) : ""} </span>
                    </Tooltip>
                  </td>

                  <td className="text-center fw-bold cursor-pointer py-8">
                    {byReleaseKey.release?.latestVersion ? <DoneOutlineIcon style={{ fill: "green" }} /> : <CloseIcon style={{ fill: "red" }} />}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1> Scripts </h1>
          <div>
            <table className="table bg-white">
              <thead>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th className="text-center fw-bold py-5 " style={{ color: "#a1b2e0" }} scope="col">Name</th>
                  <th className="text-center fw-bold py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col">Script Type</th>
                  <th className="text-center fw-bold py-5" style={{ color: "#a1b2e0" }} scope="col">Hash</th>
                  <th className="text-center fw-bold py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col" onClick={() => setSORT_NESTED_INNER_TransactionCount(prev => !prev)}>
                    <span>Transactions</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_INNER_transactionCount && SORT_NESTED_INNER_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_INNER_transactionCount && SORT_NESTED_INNER_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>
                  <th className="fw-bold cursor-pointer py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col" onClick={() => setSORT_NESTED_INNER_Volume(prev => !prev)}>
                    <span>Volume</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_INNER_volume && SORT_NESTED_INNER_active === "volume" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_INNER_volume && SORT_NESTED_INNER_active === "volume" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>
                  <th className="text-center fw-bold py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col" onClick={() => setSORT_NESTED_INNER_ScriptsLocked(prev => !prev)}>
                    Scripts Balance
                    <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_INNER_scriptsLocked && SORT_NESTED_INNER_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                    <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_INNER_scriptsLocked && SORT_NESTED_INNER_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                  </th>
                  <th className="text-center fw-bold py-5 text-nowrap" style={{ color: "#a1b2e0" }} scope="col" onClick={() => setSORT_NESTED_INNER_UniqueAccounts(prev => !prev)}>
                    <span>Unq. Accounts</span>
                    <div className="flex items-center">
                      <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_INNER_uniqueAccounts && SORT_NESTED_INNER_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                      <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_INNER_uniqueAccounts && SORT_NESTED_INNER_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(byReleaseKey)
                    .length > 0
                  &&
                  byReleaseKey.scripts.length > 0
                  &&
                  byReleaseKey.scripts
                    .map((item: any, index: any) => (
                      <tr key={index}>
                        <th className="flex justify-center fw-bold py-8" scope="row">
                          {item?.name ? item?.name : "N/A"}
                        </th>

                        <td className="text-center fw-bold py-8">
                          {item?.scriptType}
                        </td>

                        <td className="text-center fw-bold py-8">
                          <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item?.hash}</span>} style={{ cursor: "pointer" }}>
                            <span>{item?.hash.slice(0, 8) + "..."}</span>
                          </Tooltip>
                        </td>

                        <td className="text-center fw-bold py-8">
                          <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.trxCount}</span>} style={{ cursor: "pointer" }}>
                            <span> {item ? convertNumber(Number(item.trxCount)) : ""} </span>
                          </Tooltip>
                        </td>
                        <td className="text-center fw-bold py-8">
                          <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.volume ? item.volume : 0}</span>} style={{ cursor: "pointer" }}>
                            <span> {item ? (item.volume ? convertNumber(Number(item.volume)) : 0) + "  ₳" : ""} </span>
                          </Tooltip>
                        </td>

                        <td className="text-center fw-bold py-8">
                          <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.scriptsLocked ? item.scriptsLocked : 0}</span>} style={{ cursor: "pointer" }}>
                            <span> {item ? (item.scriptsLocked ? convertNumber(Number(item.scriptsLocked)) : 0) + "  ₳" : ""} </span>
                          </Tooltip>
                        </td>
                        <td className="text-center fw-bold py-8">
                          <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.uniqueAccounts ? item.uniqueAccounts : 0}</span>} style={{ cursor: "pointer" }}>
                            <span> {item ? (item.uniqueAccounts ? convertNumber(Number(item.uniqueAccounts)) : 0) + "  ₳" : ""} </span>
                          </Tooltip>
                        </td>

                      </tr>
                    ))
                }
              </tbody>
            </table>
          </div>
        </>
      </Modal>
    );
  }


  const screen1200 = useMediaQuery('(min-width:1200px)');
  const screen824 = useMediaQuery('(min-width:824px)');
  const screen784 = useMediaQuery('(min-width:784px)');
  const screen736 = useMediaQuery('(min-width:736px)');
  const screen700 = useMediaQuery('(min-width:700px)');
  const screen425 = useMediaQuery('(min-width:425px)');
  const screen390 = useMediaQuery('(min-width:390px)');
  const screen349 = useMediaQuery('(min-width:349px)');
  const screen312 = useMediaQuery('(min-width:312px)');


  if (isLoading) {
    return (
      <>
        <Dialog
          open={isLoading}
          className="dialog"
          classes={{ paper: classes.paper }}
        >
          <CircularProgress size={55} />
        </Dialog>
      </>
    )
  }


  const component = (
    <>
      <div className={`card ${className}`} style={{ marginTop: "-2.3rem" }}>
        <MyVerticallyCenteredModal
          show={openPopup}
          onHide={() => {
            if (
              findRelease
                .length > 0
              &&
              Object.keys(byReleaseKey)
                .length > 0
            ) {
              dispatch(setting_OPENPOPUP(false));
              dispatch(setting_CLOSINGPROCESS(true));
              navigate(`/releases/${param.releases}`);
              /*RESET*/
              setByReleaseKey([]);
              setSelected_byReleaseKey("");
            }
          }}
        />

        {/* begin::Header */}
        <div className={`d-flex justify-content-center ${screen700 ? `mt-5` : `mt-8`}`}>
          {
            screen700 && (
              <a href="https://adriapool.net" target="_blank">
                <img src={advertisement} alt="banner" style={{ width: screen736 ? (screen784 ? (screen824 ? (screen1200 ? "720px" : "421px") : "371px") : "340px") : "301px", height: screen736 ? (screen784 ? (screen824 ? (screen1200 ? "90px" : "51px") : "45px") : "41px") : "36px", paddingRight: "1rem", marginLeft: "1rem" }} />
              </a>
            )
          }
          {
            !screen700 && (
              <a href="https://adriapool.net" target="_blank" className="w-full mb-12 d-flex justify-content-center" style={{ marginTop: "-7px", marginLeft: "2px" }}>
                <img src={advertisement} alt="banner" style={{ width: screen312 ? (screen349 ? (screen390 ? (!screen425 ? "310px" : "350px") : "280px") : "240px") : "210px", height: screen312 ? (screen349 ? (screen390 ? (!screen425 ? "41px" : "46px") : "38px") : "38px") : "32px", paddingRight: "1rem" }} />
              </a>
            )
          }
        </div>
        <div className="card-header2 border-0 pt-5 mb-5 d-flex align-items-center" style={{ marginTop: screen700 ? "" : "-3rem" }}>
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <h3 className='card-title align-items-start flex-column'>
              <div style={{ display: findRelease.length === 0 ? "block" : "none" }}>
                <UsersListSearchComponent userInput={userInput} />
              </div>

              <IconButton
                size="medium"
                style={{ display: findRelease.length === 0 ? "none" : "block", backgroundColor: "#1fa0c7", color: "white", marginBottom: "0.625rem", width: "3.05rem" }}
                onClick={() => {
                  if (
                    findRelease
                      .length > 0
                  ) {
                    navigate("/");
                    dispatch(setting_OPENPOPUP(false));
                    dispatch(setting_CLOSINGPROCESS(false));
                    /*RESET*/
                    setFindRelease([]);
                    setSelected_findRelease("");
                  }
                }}>
                <ArrowBackIcon fontSize="medium" />
              </IconButton>
            </h3>
            {
              screen784 && (
                <div className='card-toolbar d-flex justify-content-center pl-2'>
                  {
                    keys.map((item: any, i: number) => (
                      <a
                        className={`${(i === active) && `active`} btn btn-sm btn-color-muted btn-active btn-active-primary px-4`}
                        id='kt_charts_widget_3_week_btn'
                        onClick={() => { setActive(i); }}
                        style={{ fontSize: screen700 ? "1rem !important" : "0.7rem !important" }}
                      >
                        {item}
                      </a>
                    ))
                  }
                </div>
              )
            }
          </div>
          <div style={{ display: findRelease.length === 0 ? "block" : "none" }}>
            <UsersListFilter values={categories} filter={filter} />
          </div>
        </div>
        <div>
          {
            !screen784 && (
              <>
                <div className='card-toolbar d-flex justify-content-center pl-2 pb-5' style={{ marginTop: "-1rem" }}>
                  {
                    keys.map((item: any, i: number) => (
                      <a
                        className={`${(i === active) && `active`} btn btn-sm btn-color-muted btn-active btn-active-primary px-4`}
                        id='kt_charts_widget_3_week_btn'
                        onClick={() => { setActive(i); }}
                        style={{ fontSize: screen700 ? "0.05rem !important" : "0.7rem !important" }}
                      >
                        {item}
                      </a>
                    ))
                  }
                </div>
              </>
            )
          }
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3' style={{ height: '100vh', overflowY: 'auto', marginTop: "-1.75rem" }}>

          <div onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.preventDefault()} className="pb-5 px-1">
            <Breadcrumbs>
              <Typography
                className="cursor-pointer"
                onClick={() => {
                  if (
                    (
                      findRelease
                        .length > 0
                    )
                    ||
                    (
                      findRelease
                        .length > 0
                      &&
                      Object.keys(byReleaseKey)
                        .length > 0
                    )
                  ) {
                    navigate("/");
                    dispatch(setting_OPENPOPUP(false));
                    dispatch(setting_CLOSINGPROCESS(false));
                    /*RESET*/
                    setFindRelease([]);
                    setSelected_findRelease("");
                    /*RESET*/
                    setByReleaseKey([]);
                    setSelected_byReleaseKey("");
                  }
                }}
                color={
                  findRelease
                    .length === 0
                    ? "blue" : ""
                }
              >
                {
                  findRelease
                    .length === 0
                    ? `` : `List of dApps`
                }
              </Typography>
              {
                findRelease
                  .length > 0
                && (
                  <Typography
                    className="cursor-pointer"
                    onClick={() => {
                      if (
                        findRelease
                          .length > 0
                        &&
                        Object.keys(byReleaseKey)
                          .length > 0
                      ) {
                        /*RESET*/
                        setByReleaseKey([]);
                        setSelected_byReleaseKey("");
                      }
                    }}
                    color={
                      (
                        findRelease
                          .length > 0
                        &&
                        Object.keys(byReleaseKey)
                          .length === 0
                      )
                        ? "blue" : ""
                    }
                  >
                    {selected_findRelease} releases
                  </Typography>
                )
              }
              {
                findRelease
                  .length > 0
                &&
                Object.keys(byReleaseKey)
                  .length > 0
                && (
                  <Typography
                    className="cursor-pointer"
                    color="blue"
                  >
                    {selected_byReleaseKey} scripts
                  </Typography>
                )
              }
            </Breadcrumbs>
          </div>

          <div className='tab-content'>
            {/* begin::Tap pane */}
            <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead
                    style={{
                      backgroundColor: "#f5f5f5",
                      // position: "absolute",
                      // top: "0",
                      zIndex: "1000"
                    }}
                  >
                    <tr className='border-0'>
                      <th className='p-0' style={{ width: "10%" }}>
                        <h5 className={classes.heading} style={{ marginTop: "-5.15rem" }}>Logo</h5>
                      </th>
                      <th className='p-0 text-left' style={{ width: "10%" }} onClick={() => {
                        if (
                          findRelease
                            .length === 0
                        ) {
                          setSORT_name(prev => !prev);
                        }
                        else {
                          setSORT_NESTED_name(prev => !prev);
                        }
                      }}>
                        <h5 className={classes.heading} style={{ width: "12rem", marginTop: "-5rem" }}>
                          Name<br />
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_name && SORT_active === "name" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_name && SORT_active === "name" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_name && SORT_NESTED_active === "name" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_name && SORT_NESTED_active === "name" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      <th className='p-0' style={{ width: "10%", marginTop: "-5rem" }} onClick={() => {
                        if (
                          findRelease
                            .length === 0
                        ) {
                          setSORT_category(prev => !prev);
                        }
                        else {
                          setSORT_NESTED_version(prev => !prev);
                        }
                      }}>
                        <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }}>
                          {(
                            findRelease
                              .length === 0
                          ) ? (
                            <> Category <br /> </>
                          ) : (
                            <> Version <br /> </>
                          )}
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_category && SORT_active === "category" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_category && SORT_active === "category" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_version && SORT_NESTED_active === "version" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_version && SORT_NESTED_active === "version" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      <th className='p-0' style={{ width: "10%" }}>
                        <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }} onClick={() => {
                          if (
                            findRelease
                              .length === 0
                          ) {
                            setSORT_TransactionCount(prev => !prev);
                          }
                          else {
                            setSORT_NESTED_TransactionCount(prev => !prev);
                          }
                        }}>
                          Transactions<br />
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_transactionCount && SORT_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_transactionCount && SORT_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_transactionCount && SORT_NESTED_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_transactionCount && SORT_NESTED_active === "transactionsCount" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      <th className='p-0' style={{ width: "10%" }}>
                        <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }} onClick={() => {
                          if (
                            findRelease
                              .length === 0
                          ) {
                            setSORT_Volume(prev => !prev);
                          }
                          else {
                            setSORT_NESTED_Volume(prev => !prev);
                          }
                        }}>
                          Volume<br />
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_volume && SORT_active === "volume" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_volume && SORT_active === "volume" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_volume && SORT_NESTED_active === "volume" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_volume && SORT_NESTED_active === "volume" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      <th className='p-0' style={{ width: "10%" }}>
                        <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }} onClick={() => {
                          if (
                            findRelease
                              .length === 0
                          ) {
                            setSORT_ScriptsLocked(prev => !prev);
                          }
                          else {
                            setSORT_NESTED_ScriptsLocked(prev => !prev);
                          }
                        }}>
                          Scripts Balance<br />
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_scriptsLocked && SORT_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_scriptsLocked && SORT_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_scriptsLocked && SORT_NESTED_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_scriptsLocked && SORT_NESTED_active === "scriptsLocked" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      <th className='p-0' style={{ width: "10%" }}>
                        <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }} onClick={() => {
                          if (
                            findRelease
                              .length === 0
                          ) {
                            setSORT_UniqueAccounts(prev => !prev);
                          }
                          else {
                            setSORT_NESTED_UniqueAccounts(prev => !prev);
                          }
                        }}>
                          Unq. Accounts<br />
                          {
                            findRelease
                              .length === 0
                              ? (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_uniqueAccounts && SORT_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_uniqueAccounts && SORT_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              ) : (
                                <>
                                  <ArrowUpwardIcon fontSize="small" className={SORT_NESTED_uniqueAccounts && SORT_NESTED_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                                  <ArrowDownwardIcon fontSize="small" className={!SORT_NESTED_uniqueAccounts && SORT_NESTED_active === "uniqueAccounts" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                                </>
                              )
                          }
                        </h5>
                      </th>
                      {
                        (
                          findRelease
                            .length !== 0
                        ) && (
                          <th className='p-0' style={{ width: "10%", }}>
                            <h5 className={classes.heading} style={{ marginLeft: "-0.6rem" }}>Latest</h5>
                          </th>
                        )
                      }
                      {
                        (
                          findRelease
                            .length === 0
                        ) && (
                          <th className='text-center p-0 cursor-pointer' style={{ width: "2%", marginLeft: "-2rem", color: "#90a5db" }} onClick={() => {
                            setSORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc(prev => !prev);
                          }}>
                            <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>Last epoch activity</span>} style={{ cursor: "pointer" }}>
                              <p style={{ whiteSpace: "nowrap", fontSize: "1rem", fontWeight: "bold", marginTop: "-4.2rem", marginLeft: "-0.6rem" }}>1(5d)</p>
                            </Tooltip>
                            <div style={{ marginTop: "-1.215rem" }}>
                              <ArrowUpwardIcon fontSize="small" className={SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc && SORT_active === "lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                              <ArrowDownwardIcon fontSize="small" className={!SORT_lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc && SORT_active === "lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                            </div>
                          </th>
                        )
                      }
                      {
                        (
                          findRelease
                            .length === 0
                        ) && (
                          <th className='text-center p-0 cursor-pointer' style={{ width: "2%", marginLeft: "-2rem", color: "#90a5db" }} onClick={() => {
                            setSORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc(prev => !prev);
                          }}>
                            <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>Last 6 epochs activity</span>} style={{ cursor: "pointer" }}>
                              <p style={{ whiteSpace: "nowrap", fontSize: "1rem", fontWeight: "bold", marginTop: "-4.2rem", marginLeft: "-0.6rem" }}>6(30d)</p>
                            </Tooltip>
                            <div style={{ marginTop: "-1.215rem" }}>
                              <ArrowUpwardIcon fontSize="small" className={SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc && SORT_active === "lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                              <ArrowDownwardIcon fontSize="small" className={!SORT_lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc && SORT_active === "lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                            </div>
                          </th>
                        )
                      }
                      {
                        (
                          findRelease
                            .length === 0
                        ) && (
                          <th className='text-center p-0 cursor-pointer' style={{ width: "2%", marginLeft: "-2rem", color: "#90a5db" }} onClick={() => {
                            setSORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc(prev => !prev);
                          }}>
                            <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>Last 18 epochs activity</span>} style={{ cursor: "pointer" }}>
                              <p style={{ whiteSpace: "nowrap", fontSize: "1rem", fontWeight: "bold", marginTop: "-4.2rem" }}>18(90d)</p>
                            </Tooltip>
                            <div style={{ marginTop: "-1.215rem" }}>
                              <ArrowUpwardIcon fontSize="small" className={SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc && SORT_active === "lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginRight: "-1.5px" }} />
                              <ArrowDownwardIcon fontSize="small" className={!SORT_lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc && SORT_active === "lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc" ? classes.highlighted : ""} sx={{ marginLeft: "-1.5px" }} />
                            </div>
                          </th>
                        )
                      }
                      {
                        (
                          findRelease
                            .length === 0
                        ) ? (
                          <th className='text-center p-0'>
                            <h5 className={classes.heading} style={{ marginTop: "-5rem", marginLeft: "-1.75rem" }}>Links</h5>
                          </th>
                        ) : (
                          <th className='text-center p-0'>
                            <h5 className={classes.heading}>Links</h5>
                          </th>
                        )
                      }
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  {/* map here          */}

                  <>
                    {
                      findRelease
                        .length === 0
                        ? (
                          modifiedArray(
                            getData()
                          )
                            .map((item: any, index: any) => {
                              return (
                                <tbody>
                                  <tr key={index}>
                                    <td>
                                      <div className='symbol symbol-45px me-2' style={{ marginLeft: "1rem" }}>
                                        <a href={item.link} target='_blank' className='symbol-label'>
                                          <img src={item?.icon} className='h-11 align-self-center' alt='icon' />
                                        </a>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center' style={{ width: "130px" }}>
                                        <a href={item?.name} style={{ color: "black" }}>
                                          {item?.fullName ? item.fullName : item?.name}
                                        </a>
                                      </div>

                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: item?.subCategory?.length > 12 ? "column" : "row", margin: "0px auto", width: "2.5rem" }}>
                                        {item?.subCategory && <span className={classnames('text-muted fw-bold d-block text-center', classes.subcategory)}>{item?.subCategory}</span>}                                        {item?.lastVersionContractsAuditedLink || item?.lastVersionContractsOpenSourcedLink ? <div className='symbol symbol-45px me-2'>
                                          <span className='symbol-label' style={{ width: (item?.lastVersionContractsAuditedLink && item?.lastVersionContractsOpenSourcedLink) ? "6.45rem" : "", marginLeft: "-0.35rem" }}>
                                            {item?.lastVersionContractsAuditedLink && <a target="blank" href={item?.lastVersionContractsAuditedLink} style={{ width: "4rem", height: "4rem", marginTop: "2rem", marginLeft: "0.75rem", cursor: "pointer" }}><img src={audit} className='h-50 align-self-center' alt='icon' title="Audited" /></a>}
                                            {item?.lastVersionContractsOpenSourcedLink && <a target="blank" href={item?.lastVersionContractsOpenSourcedLink} style={{ width: "4rem", height: "4rem", marginTop: "2rem", marginLeft: (item?.lastVersionContractsAuditedLink && item?.lastVersionContractsOpenSourcedLink) ? "0.05rem" : "0.75rem", cursor: "pointer" }}><img src={openSource} className='h-50 align-self-center' alt='icon' title="Open Source" /></a>}
                                          </span>
                                        </div> : ""}
                                      </div>
                                    </td>
                                    <td className='text-center text-muted fw-bold'>
                                      <p className={classnames(classes.category, "mt-3")} style={{ marginLeft: "-0.6rem" }}>{item.category}</p>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.trxCount}</span>} style={{ cursor: "pointer" }}>
                                        {/* {
                                          active === 0 ? (
                                            <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)}>
                                              {item ? convertNumber(Number(item?.trxCount?.trxCount)) : ""}
                                            </p>
                                          ) : ( */}
                                            <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)}>
                                              {item ? convertNumber(Number(item.trxCount)) : ""}
                                            </p>
                                          {/* )
                                        } */}
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.volume}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)} style={{ whiteSpace: "nowrap" }}>
                                          {item ? convertNumber(Number(item.volume)) + " ₳" : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.scriptsLocked}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)} style={{ whiteSpace: "nowrap" }}>
                                          {item ? convertNumber(Number(item.scriptsLocked)) + " ₳" : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.uniqueAccounts}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)} style={{ whiteSpace: "nowrap" }}>
                                          {item ? convertNumber(Number(item.uniqueAccounts)) : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td className='text-center'>
                                      <span
                                        style={
                                          (
                                            (
                                              item
                                                ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            )
                                            &&
                                            (
                                              item
                                                ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ).toFixed(0)
                                          )
                                            ?
                                            (
                                              (
                                                item
                                                  ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                              ).toFixed(0)
                                                > 0
                                                ?
                                                { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                                :
                                                { color: "red", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                            )
                                            :
                                            { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                        }
                                      >
                                        {
                                          (
                                            item
                                              ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                          )
                                            ?
                                            (
                                              item
                                                ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ).toFixed(0) + "%"
                                            :
                                            (
                                              item
                                                ?.lastEpochDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ) + "%"
                                        }
                                      </span>
                                    </td>

                                    <td className='text-center'>
                                      <span
                                        style={
                                          (
                                            (
                                              item
                                                ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                            )
                                            &&
                                            (
                                              item
                                                ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                            ).toFixed(0)
                                          )
                                            ?
                                            (
                                              (
                                                item
                                                  ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                              ).toFixed(0)
                                                > 0
                                                ?
                                                { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                                :
                                                { color: "red", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                            )
                                            :
                                            { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                        }
                                      >
                                        {
                                          (
                                            item
                                              ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                          )
                                            ?
                                            (
                                              item
                                                ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                            ).toFixed(0) + "%"
                                            :
                                            (
                                              item
                                                ?.lastMonthDeltaWithOnlyClosedEpochsdef_activityDiffPerc
                                            ) + "%"
                                        }
                                      </span>
                                    </td>

                                    <td className='text-center'>
                                      <span
                                        style={
                                          (
                                            (
                                              item
                                                ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            )
                                            &&
                                            (
                                              item
                                                ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ).toFixed(0)
                                          )
                                            ?
                                            (
                                              (
                                                item
                                                  ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                              ).toFixed(0)
                                                > 0
                                                ?
                                                { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                                :
                                                { color: "red", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                            )
                                            :
                                            { color: "green", fontWeight: "bold", fontSize: "1.015rem", marginTop: "17px", letterSpacing: "0.35px" }
                                        }
                                      >
                                        {
                                          (
                                            item
                                              ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                          )
                                            ?
                                            (
                                              item
                                                ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ).toFixed(0) + "%"
                                            :
                                            (
                                              item
                                                ?.lastQuarterDeltaWithOnlyClosedEpochs_activityDiffPerc
                                            ) + "%"
                                        }
                                      </span>
                                    </td>

                                    <td>
                                      <span className='badge' style={{ marginLeft: "-1.4rem" }}>
                                        <a href={item.link} target='_blank' style={{ marginRight: -10 }}>
                                          <TbWorld size={20} style={{ color: '#73aeb8' }} />
                                        </a>
                                        <div style={{ color: 'blue', paddingLeft: '6px' }}> | </div>
                                        <span className='badge'>
                                          <a href={item.twitter} target='_blank' style={{ marginLeft: -10 }}>
                                            <AiOutlineTwitter size={20} style={{ color: 'blue' }} />
                                          </a>
                                        </span>
                                        <div style={{ color: 'blue', marginLeft: '-15px' }}> | </div>
                                        <span className='badge'>
                                          <a
                                            onClick={async () => {
                                              await axios.get(`/dapps/find-release/${item?.id}`).then((res) => setFindRelease(res.data));
                                              setSelected_findRelease(item?.fullName ? item.fullName : item?.name);
                                              navigate(`/releases/${item?.name}`);
                                            }}
                                            style={{ marginLeft: -10, cursor: "pointer" }}
                                          >
                                            <PestControlIcon style={{ color: 'black' }} />
                                          </a>
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                        ) : (
                          findRelease
                            .map((item: any, index: any) => {
                              return (
                                <tbody>
                                  <tr key={index}>
                                    <td>
                                      <div className='symbol symbol-45px me-2' style={{ marginLeft: "1rem" }}>
                                        <span className='symbol-label'>
                                          <img src={item?.icon} className='h-11 align-self-center' alt='icon' />
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center'>
                                        <span
                                          className="cursor-pointer"
                                          onClick={async () => {
                                            await axios.get(`/dapps/by-release-key/${item?.key}`).then((res) => setByReleaseKey(res.data));
                                            setSelected_byReleaseKey(item?.fullName ? item.fullName : item?.name);
                                            dispatch(setting_OPENPOPUP(true));
                                            dispatch(setting_CLOSINGPROCESS(false));
                                            navigate(`/releases/${item?.name}/${item?.fullName}`);
                                          }}
                                        >
                                          {item?.fullName ? item.fullName : item?.name}
                                        </span>
                                      </div>

                                      <div style={{ display: "flex", justifyContent: "center", margin: "0px auto", alignItems: "center" }}>
                                        <span className={classnames('text-muted fw-bold d-block text-center', classes.subcategory)}>{item?.subCategory} </span>
                                        {item?.contractsAuditedLink || item?.contractOpenSourcedLink ? <div className='symbol symbol-45px me-2'>
                                          <span className='symbol-label' style={{ width: (item?.contractsAuditedLink && item?.contractOpenSourcedLink) ? "6.45rem" : "", marginLeft: "-0.35rem" }}>
                                            {item?.contractsAuditedLink && <a target="blank" href={item?.contractsAuditedLink} style={{ width: "4rem", height: "4rem", marginTop: "2rem", marginLeft: "0.75rem", cursor: "pointer" }}><img src={audit} className='h-50 align-self-center' alt='icon' title="Audited" /></a>}
                                            {item?.contractOpenSourcedLink && <a target="blank" href={item?.contractOpenSourcedLink} style={{ width: "4rem", height: "4rem", marginTop: "2rem", marginLeft: (item?.contractsAuditedLink && item?.contractOpenSourcedLink) ? "0.05rem" : "0.75rem", cursor: "pointer" }}><img src={openSource} className='h-50 align-self-center' alt='icon' title="Open Source" /></a>}
                                          </span>
                                        </div> : ""}
                                      </div>
                                    </td>
                                    <td className='text-center text-muted fw-bold'>
                                      <p className={classnames(classes.category, "mt-3")}>{item.releaseNumber}</p>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.transactionsCount}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)}>
                                          {item ? convertNumber(Number(item.transactionsCount)) : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.volume}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)} style={{ whiteSpace: "nowrap" }}>
                                          {item ? convertNumber(Number(item.volume)) + "  ₳" : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.scriptsLocked}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)} style={{ whiteSpace: "nowrap" }}>
                                          {item ? convertNumber(Number(item.scriptsLocked)) + "  ₳" : ""}
                                        </p>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Tooltip title={<span style={{ color: "white", fontSize: "1.235rem" }}>{item.uniqueAccounts}</span>} style={{ cursor: "pointer" }}>
                                        <p className={classnames('text-dark fw-bolder text-hover-primary mb-1 fs-6 text-center', classes.category)}>
                                          {item ? convertNumber(Number(item.uniqueAccounts)) : ""}
                                        </p>
                                      </Tooltip>
                                    </td>

                                    <td className="d-flex justify-content-center">
                                      {item?.latestVersion && <DoneOutlineIcon style={{ fill: "green" }} />}
                                    </td>

                                    <td>
                                      <span className='badge' style={{ marginLeft: "1.4rem" }}>
                                        <a href={item.link} target='_blank' style={{ marginRight: -10 }}>
                                          <TbWorld size={20} style={{ color: '#73aeb8' }} />
                                        </a>
                                        <div style={{ color: 'blue', paddingLeft: '6px' }}> | </div>
                                        <span className='badge'>
                                          <a href={item.twitter} target='_blank' style={{ marginLeft: -10 }}>
                                            <AiOutlineTwitter size={20} style={{ color: 'blue' }} />
                                          </a>
                                        </span>
                                        <div style={{ color: 'blue', marginLeft: '-15px' }}> | </div>
                                        <span className='badge'>
                                          <a
                                            onClick={async () => {
                                              await axios.get(`/dapps/by-release-key/${item?.key}`).then((res) => setByReleaseKey(res.data));
                                              setSelected_byReleaseKey(item?.fullName ? item.fullName : item?.name);
                                              dispatch(setting_OPENPOPUP(true));
                                              dispatch(setting_CLOSINGPROCESS(false));
                                              navigate(`/releases/${item?.name}/${item?.fullName}`);
                                            }}
                                            style={{ marginLeft: -10, cursor: "pointer" }}
                                          >
                                            <PestControlIcon style={{ color: 'black' }} />
                                          </a>
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                        )
                    }
                  </>

                  {/* ---end of map--- */}

                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
            {/* begin::Tap pane */}
            <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='border-0'>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-110px'></th>
                      <th className='p-0 min-w-50px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Brad Simmons
                        </a>
                        <span className='text-muted fw-bold d-block'>Movie Creator</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>React, HTML</td>
                      <td className='text-end'>
                        <span className='badge badge-light-success'>Approved</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Popular Authors
                        </a>
                        <span className='text-muted fw-bold d-block'>Most Successful</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>Python, MySQL</td>
                      <td className='text-end'>
                        <span className='badge badge-light-warning'>In Progress</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Active Customers
                        </a>
                        <span className='text-muted fw-bold d-block'>Movie Creator</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>AngularJS, C#</td>
                      <td className='text-end'>
                        <span className='badge badge-light-danger'>Rejected</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
            {/* begin::Tap pane */}
            <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='border-0'>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-110px'></th>
                      <th className='p-0 min-w-50px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Bestseller Theme
                        </a>
                        <span className='text-muted fw-bold d-block'>Best Customers</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>ReactJS, Ruby</td>
                      <td className='text-end'>
                        <span className='badge badge-light-warning'>In Progress</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Active Customers
                        </a>
                        <span className='text-muted fw-bold d-block'>Movie Creator</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>AngularJS, C#</td>
                      <td className='text-end'>
                        <span className='badge badge-light-danger'>Rejected</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          New Users
                        </a>
                        <span className='text-muted fw-bold d-block'>Awesome Users</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>Laravel,Metronic</td>
                      <td className='text-end'>
                        <span className='badge badge-light-primary'>Success</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='symbol symbol-45px me-2'>
                          <span className='symbol-label'>
                            <img
                              src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                              className='h-50 align-self-center'
                              alt=''
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          Popular Authors
                        </a>
                        <span className='text-muted fw-bold d-block'>Most Successful</span>
                      </td>
                      <td className='text-end text-muted fw-bold'>Python, MySQL</td>
                      <td className='text-end'>
                        <span className='badge badge-light-warning'>In Progress</span>
                      </td>
                      <td className='text-end'>
                        <a
                          href='#'
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-2'
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  {/* end::Table body */}
                </table>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Tap pane */}
          </div>
        </div>
        {/* end::Body */}
      </div >

      <div style={{ marginLeft: "0.1rem", paddingTop: "0.845rem" }} className={`card bg-transparent`}>
        <div className="d-flex align-items-center">
          <span style={{ fontWeight: "bold", letterSpacing: "0.25px", wordSpacing: "1px" }}> Donate to us : </span>
          <CopyToClipboard text="addr1qxem5shnl9ywcwkpp9ya0lsc9ys4kuc8yvkqgd6lh64x4ls0yf9r4tvp3c0fah48rm6hegkezf809nuykrndzy007emsx776cz">
            <IconButton size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </CopyToClipboard>
        </div>
        <span className="mt-1.5"> addr1qxem5shnl9ywcwkpp9ya0lsc9ys4kuc8yvkqgd6lh64x4ls0yf9r4tvp3c0fah48rm6hegkezf809nuykrndzy007emsx776cz </span>
      </div>
    </>
  )

  return (
    <>
      {!isLoading && component}
    </>
  )
}

export { TablesWidget5 }
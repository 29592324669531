import { Fragment } from 'react'
// import NextImage from 'next/image'
// import { faWallet, faChevronDown } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Transition } from '@headlessui/react'
import { config } from '../../../shared/config'
import { useWallet } from '../useWallet'
import eternl from './wallet-logos/eternl.png'
import flint from './wallet-logos/flint.svg'
import nami from './wallet-logos/nami.svg'
import yoroi from './wallet-logos/yoroi.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useMediaQuery from '@mui/material/useMediaQuery';


function shortenAddress(address: string = '') {
  return `${address.slice(0, 4)}...${address.slice(-4)}`
}

function formatBalance(balance: number) {
  console.log(balance)
  return (Number(balance) / 1000000).toFixed(2) + ' ₳'
}

//@ts-ignore
export const ConnectWallet = (props) => {
  const { wallet, status, selectWallet, disconnectWallet } = useWallet()
  const screen425 = useMediaQuery('(min-width:425px)');
  const screen490 = useMediaQuery('(min-width:490px)');


  return (
    <div {...props}>
      {status === 'connected' && (
        <Popover className="relative">
          <Popover.Button>
            <div className="flex items-center relative text-white">
              <div className="flex items-center z-10 p-3 bg-gray-100 text-primary rounded-full border-2 border-primary -mr-2">
                {/* <FontAwesomeIcon icon={faWallet} /> */}
                <AccountBalanceWalletIcon fontSize='large'/>
              </div>
              <div
                className="bg-transparent border-2 hover:bg-slate-200/25 transition-all  text-slate-200 pl-4 pr-3 rounded-l-none rounded-3xl  py-1 -ml-2 flex items-center tracking-wider"
                title={wallet.address}
              >
                {shortenAddress(wallet.address)}

                <div className="ml-2 flex items-center bg-white/90 rounded-full p-[2px]">
                  <img
                    className=""
                    height={22}
                    width={22}
                    //@ts-ignore
                    src = {config.wallets[wallet.walletKey].logo === "eternl" ? eternl : config.wallets[wallet.walletKey].logo === "flint" ? flint : config.wallets[wallet.walletKey].logo === "nami" ? nami : yoroi}
                    alt=""
                  />
                </div>
                <ArrowDropDownIcon fontSize="large"/>
              </div>
            </div>
          </Popover.Button>

          <Popover.Panel className="absolute z-10">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-100 bg-opacity-70 text-slate-200 p-7 border border-slate-800">
                  <div className="flex justify-between mb-3">
                    <div className="text-lg">Connected to:</div>
                    <div className="flex items-center text-lg">
                      <div className="mr-2 flex items-center bg-white/90 rounded-full p-[2px]">
                        <img
                          className=""
                          height={22}
                          width={22}
                          //@ts-ignore
                          src = {config.wallets[wallet.walletKey].logo === "eternl" ? eternl : config.wallets[wallet.walletKey].logo === "flint" ? flint : config.wallets[wallet.walletKey].logo === "nami" ? nami : yoroi}
                          alt=""
                        />
                      </div>
                      {/*@ts-ignore*/}
                      {config.wallets[wallet.walletKey].name}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-lg">Balance:</div>
                    <div className="text-lg">{formatBalance(wallet.balance)}</div>
                  </div>
                  <div className="flex justify-center mt-4 border-t border-t-slate-800 pt-4">
                    <button
                      className="text-red-500 text-xl"
                      onClick={disconnectWallet}
                    >
                      Disconnect
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover.Panel>
        </Popover>
      )}

      {status === 'disconnected' && (
        <button className={`bg-transparent border-2 ${screen425?(screen490?`py-3 px-7`:`py-1 px-4`):`py-1 px-1.5`} rounded-lg text-white outline-none`} style={{ fontSize: screen425?(screen490?`1rem`:`0.9rem`):`0.8rem` }} onClick={selectWallet}>
          Connect {!screen490&&<br/>}Wallet
        </button>
      )}

      {status === 'connecting' && (
        <button className="bg-transparent border-2 py-3 px-7 rounded-lg text-white">
          Connecting...
        </button>
      )}
    </div>
  )
}
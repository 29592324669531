import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


type Props = {
  className: string;
  tableData?: any;
  heading: string;
}

export default function InflowOutflowChart({
  className,
  tableData,
  heading
}: Props) {
  const screen585 = useMediaQuery('(min-width:585px)');


  const [vertical, setVertical] = useState<any>([]);
  const [horizontal, setHorizontal] = useState<any>([]);
  useEffect(() => {
    if (tableData) {
      setVertical(
        Object.keys(tableData)
          .sort()
      );

      setHorizontal(
        Object.values(tableData)
          .map((item: any) => item.inflowsOutflows)
      )
    }
  }, [tableData]);


  const keys = [
    'All Time',
    '18 epochs',
    '6 epochs',
    "Current"
  ];
  const [active, setActive] = useState<number>(2);
  const [current_graph, setCurrent_graph] = useState({ vertical: [], horizontal: [] });
  useEffect(() => {
    if(active === 0) {
      setCurrent_graph({
        vertical: vertical.slice( 0, vertical.length - 1 ),
        horizontal: horizontal.slice( 0, vertical.length - 1 )
      });
    }
    else if(active === 1) {
      setCurrent_graph({
        vertical: vertical.slice( vertical.length - 18, vertical.length - 1 ),
        horizontal: horizontal.slice( vertical.length - 18, vertical.length - 1 )
      });
    }
    else if(active === 2) {
      setCurrent_graph({
        vertical: vertical.slice( vertical.length - 7, vertical.length - 1 ),
        horizontal: horizontal.slice( vertical.length - 7, vertical.length - 1 )
      }); 
    }     
    else if(active === 3) {
        setCurrent_graph({
          vertical: vertical.slice( vertical.length - 1 ),
          horizontal: horizontal.slice( vertical.length - 1 )
        });      
    }
  }, [vertical, horizontal, active]);


  const options = {
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
      },
    },
  };

  const [data, setData] = useState({
    labels: vertical,
    datasets: [
      {
        label: horizontal.map((value: any) => value < 0 ? 'outflow' : 'inflow'),
        data: horizontal.length === 0 ? [] : horizontal,
        backgroundColor: horizontal.map((value: any) => value < 0 ? '#FF4069' : '#4BC0C0'),
      }
    ],
  });
  useEffect(() => {
    if (
      current_graph?.vertical?.length > 0
      && current_graph?.horizontal?.length > 0
    ) {
      setData({
        labels: current_graph?.vertical,
        datasets: [
          {
            label: "₳",
            data: current_graph?.horizontal?.length === 0 ? [] : current_graph?.horizontal,
            backgroundColor: current_graph?.horizontal?.map((value: any) => value < 0 ? '#FF4069' : '#4BC0C0'),
          }
        ],
      });
    }
  }, [current_graph]);


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <h3 className='card-title align-items-start flex-column'>
        <div className={`${screen585&&`d-flex`} pb-8`}>
          <div style={{ flex: 1 }}>
            <span className='card-label fw-bolder fs-3 mb-1'>{heading}</span>
            <br />
            <span className='text-muted fw-bold fs-7'>Per Epoch</span>
          </div>
          <div className={`card-toolbar ${!screen585&&`mt-4`}`} data-kt-buttons='true'>
            {
              keys.map((item: any, i: number) => (
                <a
                  className={`${(i === active) && `active`} btn btn-sm btn-color-muted btn-active btn-active-primary px-4`}
                  id='kt_charts_widget_3_week_btn'
                  onClick={() => { setActive(i); }}
                >
                  {item}
                </a>
              ))
            }
          </div>
        </div>
      </h3>
      <div className='-mt-4 mb-4'>
        <p> Inflows <span style={{backgroundColor: '#4BC0C0', paddingLeft: "70px", height: "10px", marginLeft: "20px"}}></span> </p>
        <p> Outflows <span style={{backgroundColor: '#FF4069', paddingLeft: "70px", height: "10px", marginLeft: "7.5px"}}></span> </p>
      </div>
      {/* end::Toolbar */}
      <Bar options={options} data={data} />
    </div>
  );
}
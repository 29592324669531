/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import Cards from '../../../_metronic/partials/widgets/card'
import { useMediaQuery } from 'react-responsive';


const DashboardPage: FC = () => {
  const screen992 = useMediaQuery({ query: '(min-width: 992px)' });
  const screen888 = useMediaQuery({ query: '(min-width: 888px)' });
  const screen780 = useMediaQuery({ query: '(min-width: 780px)' });
  const screen607 = useMediaQuery({ query: '(min-width: 607px)' });
  const screen541 = useMediaQuery({ query: '(min-width: 541px)' });
  const screen513 = useMediaQuery({ query: '(min-width: 513px)' });
  const screen382 = useMediaQuery({ query: '(min-width: 382px)' });
  const screen346 = useMediaQuery({ query: '(min-width: 346px)' });
  const screen310 = useMediaQuery({ query: '(min-width: 310px)' });
  const screen286 = useMediaQuery({ query: '(min-width: 286px)' });
  const screen266 = useMediaQuery({ query: '(min-width: 266px)' });
  const screen222 = useMediaQuery({ query: '(min-width: 222px)' });


  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <p
          className='text-white paragraph'
          style={{
            margin: screen780?"":"0 auto",
            marginTop: screen222?(screen266?(screen286?(screen310?(screen780?(screen888?(screen992?"0.15rem":"-0.025rem"):"0.25rem"):"-0.5rem"):"-0.05rem"):"-0.15rem"):"-0.65rem"):"-1.125rem",
            paddingBottom: (screen992?"":"1.85rem"),
            marginBottom: screen346?"":"0.85rem",
            fontSize: screen310?(screen513?(screen888?"":"0.875rem"):"0.785rem"):"0.715rem",
            textAlign: screen780?"left":"center",
            width: screen286?(screen346?(screen382?(screen513?(screen541?(screen607?(screen780?"100%":"80%"):"90%"):"95%"):"92%"):"83%"):"93%"):"30rem",
          }}
        >
        {/* Dapps On Cardano blockchain brought to you by Adria Stake Pool and CRFA Stake Pool. This is the first version of the website. We are working on implementing new features. To help further development please consider staking with us. Let’s decentralize the World. */}
        </p>
        {/* <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div> */}
        {/* <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div> */}
        {/* <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
        <Cards />
      </div>
      {/* end::Row */}
      <div style={{ width: "100%", marginTop: '5rem' }}>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-12'/>
      </div>
      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
     
      </div>
    </div> */}
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'></div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Cardano dApp Store</PageTitle>

      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }

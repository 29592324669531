function Advertise() {
    return (
        <div className="bg-[#eee]">

            <div className="bg-white text-black pt-20 pb-14" style={{ borderRadius: "15px", padding: "55px", marginBottom: "35px" }}>
                <div className="w-[80%]" style={{ margin: "0 auto" }}>
                    <p className="text-6xl text-center font-bold" style={{ wordSpacing: "3.15px" }}>
                        Advertise with us
                    </p>

                    <p className="mt-10 leading-5 text-gray-600 text-justify">
                         Advertise Your Dapp or Project on dappsoncardano.com
                        <br />
                        <br />
                        Attract new customers and increase brand awareness with targeted advertising on dappsoncardano.com. Our website is the excelent platform to reach a large and engaged audience interested in the Cardano ecosystem, making it the perfect place to promote your dapp or project.
                        <br />
                        <br />
                        Get started today and take advantage of the opportunity to reach a highly engaged audience on dappsoncardano.com. Contact us to place your banner ad and drive more traffic to your website.
                      </p>

                    <div className="mt-7">
                        <p className="text-2xl font-bold tracking-wide" style={{ wordSpacing: "0.65px" }}>
                            Banner Placement and pricing
                        </p>
                        <p className="mt-2 leading-5 text-gray-600 text-justify">
                            We are offering banners of size 728x90 and larger on three diffrent website locations:
                             <br />
                            <br />
                             - <a href="https://dappsoncardano.com">Home page header banner</a> - 150$ per week                             
                             <br />
                             <br />
                             - <a href="https://dappsoncardano.com/analytics/globalstats"> Global stats header banner</a> - 100$ per week                             
                             <br />
                             <br />
                             - <a href="https://dappsoncardano.com/jpg.store"> Single dapp page</a> - 75$ per week                             
                             <br />
                            <br />
                             Don't miss this opportunity to reach a highly engaged and interested audience on the Cardano network. Contact us today over some of chanells bellow to place your banner ad and drive more traffic to your dapp or project.
                         </p>
                         <p>
                            Contact us on email: contact@adriapool.net <br/>
                            or any other social channels
                         </p>
                    </div>                  
                </div>
            </div>

        </div>
    )
}

export default Advertise;